<template>
  <v-app :style="colorVapp">
    <onebox_toolbar
      :parentfolder="parentfolder"
      @inputdata="filteredItems"
      @loadfile="loadfolder()"
      @callparentfolder="getSourceSystem()"
      @callstorage="loadstorage()"
    ></onebox_toolbar>
    <v-content>
      <!-- <v-divider></v-divider> -->
      <v-overlay :value="processloader" absolute :color="color.theme" dark>
        <v-progress-circular
          indeterminate
          size="64"
          color="blue"
        ></v-progress-circular>
        <br />
        <br />
        <span :style="colorProgress">&nbsp; &nbsp; loading</span>
      </v-overlay>
      <v-overlay :value="loaddataprogress_print" z-index="2" color="black" dark>
        <v-progress-circular
          indeterminate
          size="64"
          color="white"
        ></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <!-- ขนาดมือถือ โทรศัพท์ -->
      <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
        <v-card
          rounded="xl"
          class="elevation-0 d-flex align-center"
          height="40"
        >
          <v-list-item class="text-left">
            <v-btn
              @mouseover="show_back = true"
              @mouseleave="show_back = false"
              fab
              x-small
              :color="color.theme"
              @click="backtopreventfolder(), (show_back = false)"
              class="ma-2 text-left"
            >
              <v-icon color="white">keyboard_backspace</v-icon>
              <v-badge
                v-if="$t('default') === 'th'"
                style="z-index:10000;bottom:-45px;left:-35px;"
                :value="show_back"
                :content="$t('balloon.back')"
                transition="slide-y-transition"
              ></v-badge>
              <v-badge
                v-else
                style="z-index:10000;bottom:-45px;left:-27px;"
                :value="show_back"
                :content="$t('balloon.back')"
                transition="slide-y-transition"
              ></v-badge>
            </v-btn>
            <v-list-item-avatar class="text-center">
              <v-icon dark size="24" :color="color.theme">mdi-domain</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >{{ Labeldata }} ({{ $route.query.sourcesystem }})</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-list>
      <v-list nav :color="color.BG" v-else>
        <v-list-item class="text-left">
          <v-btn
            @mouseover="show_back = true"
            @mouseleave="show_back = false"
            fab
            small
            :color="color.theme"
            @click="backtopreventfolder(), (show_back = false)"
            class="ma-2"
          >
            <v-icon color="white">keyboard_backspace</v-icon>
            <v-badge
              v-if="$t('default') === 'th'"
              style="z-index:10000;bottom:-45px;left:-35px;"
              :value="show_back"
              :content="$t('balloon.back')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="z-index:10000;bottom:-45px;left:-27px;"
              :value="show_back"
              :content="$t('balloon.back')"
              transition="slide-y-transition"
            ></v-badge>
          </v-btn>
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="20">{{ defaulticon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span :style="headerPage"
                >{{ Labeldata }} ({{ $route.query.sourcesystem }})</span
              >
            </v-list-item-title>
          </v-list-item-content>
          <!-- hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh -->
          <!-- <v-list-item-icon class="mr-8">
            <v-badge :content="outboxdata.length">
              <v-icon size="30" dark :color="color.theme" @click="outboxApproved">insert_drive_file</v-icon>
            </v-badge>
            <outboxapproved
              :show="openoutboxapproved"
              @openoutboxapproved="openoutboxapproved = false"
              :approvefile="fakeData"
            />
          </v-list-item-icon> -->
        </v-list-item>
      </v-list>
      <v-card
        class="elevation-0"
        :color="color.BG"
        id="thiscontainer_outboxsourcesystem"
      >
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <!-- หน้าจอธรรมดา -->
                <div v-if="resolutionScreen >= 400">
                  <!-- ค้นหาเอกสาร -->
                  <v-expansion-panels v-model="panel" outlined>
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <div>
                        <span :style="headerPage">{{
                          $t("dcmPage.conditionFile")
                        }}</span>
                        <p class="ml-2 mt-2" style="color:#E70034">( {{ $t("conditions") }} {{ $t("textconditions") }} )</p>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap fill-height justify-center>
                            <v-flex xs12 sm12 md5 lg5 class="pa-1">
                              <v-container fluid>
                                <v-layout row wrap justify-center>
                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-dialog
                                      ref="dialogFromdatedocument"
                                      v-model="modalFromdatedocument"
                                      :return-value.sync="fromdatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.fromdatedocument'
                                            )
                                          "
                                          v-model="fromdatedocument"
                                          @click:prepend="
                                            modalFromdatedocument = true
                                          "
                                          prepend-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="fromdate_hint"
                                          @click:clear="fromdatedocument = '' , todatedocument = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true, fromdatedocument = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        prepend-icon="event"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <!-- :min="setdate_(todatedocument)" -->
                                      <!-- :max="todatedocument" -->
                                      <!-- :max="callDateNow()" -->
                                      <v-date-picker
                                        v-model="fromdatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdatedocument()"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalFromdatedocument = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closefromdatedocument"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>

                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-dialog
                                      ref="dialogTodatedocument"
                                      v-model="modalTodatedocument"
                                      :return-value.sync="todatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          readonly
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.todatedocument'
                                            )
                                          "
                                          v-model="todatedocument"
                                          prepend-icon="event"
                                          @click:prepend="
                                            modalTodatedocument = true
                                          "
                                          v-on="on"
                                          outlined
                                          dense
                                          clearable
                                          @click:clear="fromdatedocument = '' , todatedocument = ''"
                                          :persistent-hint="todate_hint"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                          :disabled="fromdatedocument === '' || fromdatedocument === null"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true, todatedocument = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="todatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="
                                          $refs.dialogTodatedocument.save(
                                            todatedocument
                                          )
                                        "
                                        :min="fromdatedocument"
                                        :max="setdate(fromdatedocument)"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalTodatedocument = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closetodatedocument"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>

                                <v-layout row wrap justify-center>
                                  <!-- ช่องวันที่ได้รับไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-dialog
                                      ref="dialogFromdateupload"
                                      v-model="modalFromdateupload"
                                      :return-value.sync="fromdateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.fromdateupload'
                                            )
                                          "
                                          v-model="fromdateupload"
                                          @click:prepend="
                                            modalFromdateupload = true
                                          "
                                          prepend-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          @click:clear="fromdateupload = '' ,todateupload = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true, fromdateupload = datenow_eng,on"
                                        prepend-icon="event"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <!-- :min="setdate_(todateupload)" -->
                                      <!-- :max="todateupload" -->
                                      <v-date-picker
                                        v-model="fromdateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdateupload()"
                                        :color="color.theme"
                                        :max="callDateNow()"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalFromdateupload = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closefromdateupload"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <!-- ช่องวันที่ได้รับไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-dialog
                                      ref="dialogTodateupload"
                                      v-model="modalTodateupload"
                                      :return-value.sync="todateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          @click:clear="fromdateupload = '' ,todateupload = ''"
                                          readonly
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.todateupload'
                                            )
                                          "
                                          v-model="todateupload"
                                          prepend-icon="event"
                                          @click:prepend="
                                            modalTodateupload = true
                                          "
                                          v-on="on"
                                          outlined
                                          dense
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                          :disabled="fromdateupload === '' || fromdateupload === null"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true, todateupload = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="todateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="
                                          $refs.dialogTodateupload.save(
                                            todateupload
                                          )
                                        "
                                        :min="fromdateupload"
                                        :max="setdate(fromdateupload)"
                                        :color="color.theme"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalTodateupload = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closetodateupload"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="receivername"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.receivername'
                                        )
                                      "
                                      prepend-icon="account_box"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <!-- ช่องเลือก สถานะส่ง onechat -->
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="statusonechat"
                                      :items="liststatusonechat"
                                      item-value="status_code"
                                      item-text="status_text"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.statussendonechat'
                                        )
                                      "
                                      prepend-icon="line_style"
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectallstatusonechat"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                statusonechat.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon_all }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span v-if="index < maxDisplay">{{
                                          item.status_text
                                        }}</span>
                                        <span
                                          v-if="index === maxDisplay"
                                          class="grey--text caption"
                                          >(+{{
                                            statusonechat.length - maxDisplay
                                          }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <!-- ช่องกรอก ผู้ส่ง -->
                                    <v-text-field
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sendername"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sendername'
                                        )
                                      "
                                      prepend-icon="account_circle"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="oneboxtype"
                                      :items="boxtype"
                                      item-text="box_type"
                                      item-value="box_value"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.receivernameextension'
                                        )
                                      "
                                      prepend-icon="line_style"
                                      :color="color.theme"
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall_boxtype"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                documenttype.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon_ }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md12 lg12>
                                    <!-- ช่องเลือก ประเภเอกสาร -->
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documenttype"
                                      :items="listDoctype"
                                      item-value="document_type_code"
                                      :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.doctype'
                                        )
                                      "
                                      prepend-icon="line_style"
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                documenttype.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span v-if="index < maxDisplay">{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }} </span>
                                        <span
                                          v-if="index === maxDisplay"
                                          class="grey--text caption"
                                          >(+{{
                                            documenttype.length - maxDisplay
                                          }}
                                          ประเภท)</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <!-- chanel -->
                                <!-- <v-layout row wrap >
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myoutboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex>
                              </v-layout> -->
                              </v-container>
                            </v-flex>
                            <div class="pt-0">
                              <div class="pt-6"  
                                @mouseover="show_info_doc = true"
                                @mouseleave="show_info_doc = false"
                                fab
                                x-small
                                  >
                                <v-icon color="primary">mdi-information</v-icon>
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                     
                              </div>
                              <div class="pt-10"  
                                @mouseover="show_info = true"
                                @mouseleave="show_info = false"
                                fab
                                x-small
                               >
                                <v-icon color="primary">mdi-information</v-icon>
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date_outbox')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date_outbox')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                     
                              </div>
                            </div>

                            <v-flex xs12 sm12 md1 lg1 class="text-center">
                              <v-divider vertical></v-divider>
                            </v-flex>

                            <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                              <v-container fluid>
                                <v-layout row wrap justify-center>
                                  <!-- ช่องกรอกชื่อไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="filename"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.filename'
                                        )
                                      "
                                      prepend-icon="insert_drive_file"
                                    ></v-text-field>
                                  </v-flex>
                                  <!-- ช่องเลือกประเภทไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="fileextension"
                                      :items="listExtensionFile"
                                      item-value="data_type"
                                      item-text="data_type"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.fileextension'
                                        )
                                      "
                                      prepend-icon="extension"
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall_fileextension"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                fileextension.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icons }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span
                                          v-if="index < maxDisplayfileextension"
                                          >{{ item.data_type }}</span
                                        >
                                        <span
                                          v-if="
                                            index === maxDisplayfileextension
                                          "
                                          class="grey--text caption"
                                          >(+{{
                                            fileextension.length -
                                              maxDisplayfileextension
                                          }}
                                          )</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      @click:clear="clearDocumentId"
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.filedocumentid'
                                        )
                                      "
                                      prepend-icon="format_list_numbered"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      :disabled="!!!documentid"
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentidto"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.todateupload'
                                        )
                                      "
                                      prepend-icon="format_list_numbered"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>

                                <!-- ช่องกรอก SellerTaxID -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellertaxid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sellertaxid'
                                        )
                                      "
                                      prepend-icon="mdi-domain"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellerbranchid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sellerbranchid'
                                        )
                                      "
                                      prepend-icon="mdi-format-list-bulleted-square"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <!-- ช่องกรอก BuyerTaxID -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyertaxid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.buyertaxid'
                                        )
                                      "
                                      prepend-icon="mdi-domain"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyerbranchid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.buyerbranchid'
                                        )
                                      "
                                      prepend-icon="mdi-format-list-bulleted-square"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center >
                                  <v-flex xs12 sm12 md12 lg12>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="ponumberinput"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.ponumber'
                                        )
                                      "
                                      prepend-icon="mdi-script-text-outline"
                                      :color="color.theme"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- ค้นหาเอกสารแบบ import แนบไฟล์ -->
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <div>
                        <span :style="headerPage">{{
                          $t("dcmPage.searchfileattachments")
                        }}</span>
                        <p class="ml-2 mt-2" style="color:#E70034">( {{ $t("conditions_file_attachments")}} )</p>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap fill-height justify-left>
                            <v-flex xs12 sm12 md12 lg12 class="pa-1">
                              <v-container>
                                <v-layout justify-left>
                                  <v-flex xs10 sm6 md6 lg6>
                                    <v-file-input
                                      :accept="'.xlsx'"
                                      v-model="import_file_document_no"
                                      :label="$t('dcmPage.please_attach_file')"
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-0"
                                    ></v-file-input>
                                  </v-flex>
                                  <v-flex xs2 sm6 md6 lg6>
                                <v-btn
                                @mouseover="show_info_import = true"
                                @mouseleave="show_info_import = false"
                                class="ml-1" 
                                icon
                                @click="download_example_file()"
                                >
                                <v-icon color="#00733B">mdi-file-excel</v-icon>
                                </v-btn>
                                <v-badge
                                  v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-40px;left:-50px;"
                                  :value="show_info_import"
                                  :content="$t('ตัวอย่างไฟล์')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge
                                  v-else
                                  style="z-index:10000;bottom:-40px;left:-55px;"
                                  :value="show_info_import"
                                  :content="$t('Example File')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>

                <!-- หน้าจอมือถือ -->
                <div v-else>
                  <!-- ค้นหาเอกสาร -->
                  <v-expansion-panels v-model="panel" outlined>
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <div>
                        <span :style="headerPage">{{
                          $t("dcmPage.conditionFile")
                        }}</span>
                        <p class="ml-2" style="color:#E70034">( {{ $t("conditions") }} {{ $t("textconditions") }} )</p>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap fill-height justify-center>
                            <v-flex xs12 sm12 md5 lg5 class="pa-1">
                              <v-container fluid>
                                <v-layout row wrap justify-center>
                                  <!-- ช่องกรอกชื่อไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="filename"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.filename'
                                        )
                                      "
                                      append-icon="mdi-magnify"
                                    ></v-text-field>
                                  </v-flex>
                                  <!-- ช่องเลือกประเภทไฟล์ -->
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="fileextension"
                                      :items="listExtensionFile"
                                      item-value="data_type"
                                      item-text="data_type"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.fileextension'
                                        )
                                      "
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall_fileextension"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                fileextension.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icons }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span
                                          v-if="index < maxDisplayfileextension"
                                          >{{ item.data_type }}</span
                                        >
                                        <span
                                          v-if="
                                            index === maxDisplayfileextension
                                          "
                                          class="grey--text caption"
                                          >(+{{
                                            fileextension.length -
                                              maxDisplayfileextension
                                          }}
                                          )</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.startdate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องวันที่ได้รับไฟล์ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogFromdateupload"
                                      v-model="modalFromdateupload"
                                      :return-value.sync="fromdateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.fromdateupload'
                                            )
                                          "
                                          v-model="fromdateupload"
                                          @click:prepend="
                                            modalFromdateupload = true
                                          "
                                          append-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          @click:clear="fromdateupload = '' , todateupload = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true, fromdateupload = datenow_eng,on"
                                        prepend-icon="event"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="fromdateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdateupload()"
                                        :color="color.theme"
                                        :min="setdate_(todateupload)"
                                        :max="todateupload"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalFromdateupload = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closefromdateupload"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <!-- <v-flex mt-2 d-flex justify-center>-</v-flex> -->
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.enddate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องวันที่ได้รับไฟล์ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogTodateupload"
                                      v-model="modalTodateupload"
                                      :return-value.sync="todateupload"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          clearable
                                          readonly
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.todateupload'
                                            )
                                          "
                                          v-model="todateupload"
                                          append-icon="event"
                                          @click:prepend="
                                            modalTodateupload = true
                                          "
                                          v-on="on"
                                          outlined
                                          dense
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true, todateupload = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="todateupload"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="
                                          $refs.dialogTodateupload.save(
                                            todateupload
                                          )
                                        "
                                        :color="color.theme"
                                        :min="fromdateupload"
                                        :max="setdate(fromdateupload)"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalTodateupload = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closetodateupload"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="receivername"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.receivername'
                                        )
                                      "
                                      append-icon="mdi-email-receive"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <!-- ช่องเลือก สถานะส่ง onechat -->
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="statusonechat"
                                      :items="liststatusonechat"
                                      item-value="status_code"
                                      item-text="status_text"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.statussendonechat'
                                        )
                                      "
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectallstatusonechat"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                statusonechat.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon_all }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span v-if="index < maxDisplay">{{
                                          item.status_text
                                        }}</span>
                                        <span
                                          v-if="index === maxDisplay"
                                          class="grey--text caption"
                                          >(+{{
                                            statusonechat.length - maxDisplay
                                          }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <!-- ช่องกรอก ผู้ส่ง -->
                                    <v-text-field
                                      outlined
                                      dense
                                      clearable
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sendername"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sendername'
                                        )
                                      "
                                      append-icon="mdi-email-send"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="oneboxtype"
                                      :items="boxtype"
                                      item-text="box_type"
                                      item-value="box_value"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.receivernameextension'
                                        )
                                      "
                                      :color="color.theme"
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall_boxtype"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                documenttype.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon_ }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md12 lg12>
                                    <!-- ช่องเลือก ประเภทเอกสาร -->
                                    <v-select
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documenttype"
                                      :items="listDoctype"
                                      item-value="document_type_code"
                                      :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.doctype'
                                        )
                                      "
                                      :item-color="color.theme"
                                      multiple
                                    >
                                      <template v-slot:prepend-item>
                                        <v-list-item
                                          ripple
                                          @click="fn_selectall"
                                        >
                                          <v-list-item-action>
                                            <v-icon
                                              :color="
                                                documenttype.length > 0
                                                  ? 'indigo darken-4'
                                                  : ''
                                              "
                                            >
                                              {{ icon }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                      </template>
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <span v-if="index < maxDisplay">{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }} </span>
                                        <span
                                          v-if="index === maxDisplay"
                                          class="grey--text caption"
                                          >(+{{
                                            documenttype.length - maxDisplay
                                          }}
                                          ประเภท)</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <!-- chanel -->
                                <!-- <v-layout row wrap >
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myoutboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex>
                              </v-layout> -->

                                <v-layout row wrap justify-center>
                                  <v-flex xs6 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      @click:clear="clearDocumentId"
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.filedocumentid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs6 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      :disabled="!!!documentid"
                                      class="pa-1 pt-0 pb-0"
                                      v-model="documentidto"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.todateupload'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.startdate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogFromdatedocument"
                                      v-model="modalFromdatedocument"
                                      :return-value.sync="fromdatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.fromdatedocument'
                                            )
                                          "
                                          v-model="fromdatedocument"
                                          @click:prepend="
                                            modalFromdatedocument = true
                                          "
                                          append-icon="event"
                                          v-on="on"
                                          readonly
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="fromdate_hint"
                                          @click:clear="todatedocument = ''"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true, fromdatedocument = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        prepend-icon="event"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <!-- :min="setdate_(todatedocument)"
                                      :max="todatedocument" -->
                                      <v-date-picker
                                        v-model="fromdatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="changeFromdatedocument()"
                                        :color="color.theme"
                                     
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalFromdatedocument = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closefromdatedocument"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>

                                  <!-- <v-flex mt-2 d-flex justify-center>-</v-flex> -->
                                  <v-flex xs2 lg3 mt-2 d-flex>
                                    <div>
                                      {{ $t("toolbar.searching.startdate") }}
                                    </div>
                                  </v-flex>
                                  <!-- ช่องกรอก เอกสารลงวันที่ -->
                                  <v-flex xs10 lg3>
                                    <v-dialog
                                      ref="dialogTodatedocument"
                                      v-model="modalTodatedocument"
                                      :return-value.sync="todatedocument"
                                      persistent
                                      full-width
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          readonly
                                          :color="color.theme"
                                          :label="
                                            $t(
                                              'myoutboxPage.conditionOptional.todatedocument'
                                            )
                                          "
                                          v-model="todatedocument"
                                          append-icon="event"
                                          @click:prepend="
                                            modalTodatedocument = true
                                          "
                                          v-on="on"
                                          outlined
                                          dense
                                          clearable
                                          :persistent-hint="todate_hint"
                                          class="my-hint-style pa-1 pt-0 pb-0"
                                        ></v-text-field>
                                        <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                        <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true, todatedocument = datenow_eng,on"
                                        :placeholder="$t('myoutboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                      </template>
                                      <v-date-picker
                                        v-model="todatedocument"
                                        scrollable
                                        :locale="$t('default')"
                                        @input="
                                          $refs.dialogTodatedocument.save(
                                            todatedocument
                                          )
                                        "
                                        :color="color.theme"
                                        :min="fromdatedocument"
                                        :max="setdate(fromdatedocument)"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click.stop="
                                            modalTodatedocument = false
                                          "
                                          >{{
                                            $t(
                                              "myoutboxPage.conditionOptional.closetodatedocument"
                                            )
                                          }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>

                                <!-- ช่องกรอก SellerTaxID -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellertaxid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sellertaxid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellerbranchid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.sellerbranchid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <!-- ช่องกรอก BuyerTaxID -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyertaxid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.buyertaxid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyerbranchid"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.buyerbranchid'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                                <!-- Po_number -->
                                <v-layout row wrap justify-center>
                                  <v-flex xs12 sm12 md12 lg12>
                                    <v-text-field
                                     clearable
                                     outlined
                                     dense
                                     class="pa-1 pt-0 pb-0"
                                     v-model="ponumberinput"
                                     :label="$t('myoutboxPage.conditionOptional.ponumber')"
                                     :color="color.theme"
                                    ></v-text-field>
                                  </v-flex>
                                 </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- ค้นหาเอกสารแบบ import แนบไฟล์ Mobile-->
                    <v-expansion-panel class="pa-1">
                      <v-expansion-panel-header>
                        <div>
                        <span :style="headerPage">{{
                          $t("dcmPage.searchfileattachments")
                        }}</span>
                        <p class="ml-2 mt-2" style="color:#E70034">( {{ $t("conditions_file_attachments")}} )</p>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-1">
                        <v-form>
                          <v-layout row wrap fill-height justify-center>
                            <v-flex xs12 sm12 md12 lg12 class="pa-1">
                              <v-container>
                                <v-layout>
                                <p v-if="$t('default') === 'th'">ตัวอย่างไฟล์สำหรับการค้นหา :</p>
                                <p v-else>File Example For Search: </p>
                                <v-btn
                                class="ml-1 mt-n2" 
                                icon
                                @click="download_example_file()"
                                >
                                <v-icon color="#00733B">mdi-file-excel</v-icon>
                                </v-btn>
                                </v-layout>
                                <v-layout justify-center>
                                    <v-file-input
                                      :accept="'.xlsx'"
                                      v-model="import_file_document_no"
                                      :label="$t('dcmPage.please_attach_file')"
                                      outlined
                                      dense
                                      class="pa-0 pt-0 pb-0 pl-0"
                                    ></v-file-input>
                                  </v-layout>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-flex>
              <v-flex xs12 lg12 class="text-center">
                <br />
                <v-btn
                  outlined
                  class="ma-1"
                  color="#2D3B4E"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="Clear_data()"
                >
                  <v-icon left>mdi-refresh</v-icon>
                  {{ $t("myoutboxPage.clear_data") }}
                </v-btn>
                <v-btn
                  v-if="panel !== 1"
                  class="ma-1 white--text"
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="(checkpagination = false), (page = 1),(searchdocument())"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t("myoutboxPage.search") }}
                </v-btn>
                <v-btn
                  v-if="panel === 1"
                  class="ma-1 white--text"
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="(checkpagination = false),(page = 1),(import_file_for_search()),(check_download_attachments = true)"
                >
                <v-icon left>mdi-file-find-outline</v-icon>
                &nbsp;
                {{ $t("myoutboxPage.search_attachments") }}
                </v-btn>
                <v-menu :close-on-content-click="closeOnContentClick">
                  <template v-slot:activator="{ on }">
                    <!-- ดาวน์โหลดเอกสารไฟล์แนบ -->
                    <v-btn
                      v-if="check_import_search === true"
                      outlined
                      class="ma-1 white-text"
                      :color="color.theme"
                      :disabled="loaddataprogress || outboxdata.length <= 0 || check_download_attachments === false"
                      :loading="loaddataprogress"
                      v-on="on"
                    >
                      {{ $t("dcmPage.download_attachments") }}
                      <v-icon>
                        keyboard_arrow_down
                      </v-icon>
                    </v-btn>
                    <!-- ดาวน์โหลดเอกสารปกติ -->
                    <v-btn
                      v-else
                      outlined
                      class="ma-1 white-text"
                      :color="color.theme"
                      :disabled="loaddataprogress || outboxdata.length <= 0"
                      :loading="loaddataprogress"
                      v-on="on"
                    >
                      {{ $t("dowloads") }}
                      <v-icon>
                        keyboard_arrow_down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template>
                      <v-container fluid>
                        <!-- <v-list-item> -->
                          <!-- Include duplicate files (รวมรายการที่ซ้ำกัน) -->
                        <v-checkbox
                          v-model="typedownloads"
                          class="mt-0"
                          :label='$t("myoutboxPage.include_duplicate_files")'
                        ></v-checkbox>
                        <!-- Summary Item (1 ไฟล์ 1 บรรทัด) -->
                        <v-checkbox
                          v-model="typeitem"
                          class="mt-0"
                          :label='$t("myoutboxPage.summary_item")'
                        ></v-checkbox>
                        <!-- </v-list-item> -->
                      </v-container>
                    </template>
                    <v-tooltip
                      bottom
                      content-class="mt-n13"
                      v-model="showToolTipExportFile"
                      :disabled="!disableDataStorageOverload"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          @click="
                            disableByPackage || disableDataStorageOverload || status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'
                              ? ''
                              : exportfile(),
                              (closeOnContentClick = true)
                          "
                          outlined
                          v-on="on"
                          :disabled="disableByPackage || status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                          :ripple="!disableDataStorageOverload"
                          @mouseover="status_permission_outbox !=='AD' && status_permission_outbox !== 'DL' ? show_download_all = false : show_download_all = true"
                          @mouseleave="show_download_all = false"
                        >
                          <v-list :disabled="disableDataStorageOverload">
                            <v-list-item-title>
                              <v-icon left :color="color.theme"
                                >mdi-archive-arrow-down</v-icon
                              >
                              {{
                                "&nbsp;&nbsp;&nbsp;" + $t("myinboxPage.export")
                              }}
                            </v-list-item-title>
                          </v-list>
                          <v-expand-transition>
                            <v-overlay
                              v-if="disableByPackage && status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.storage_and_permission") }}</span>
                              </v-tooltip>
                            </v-overlay>
                            <v-overlay
                              v-else-if="disableByPackage && status_permission_outbox === 'AD' && status_permission_outbox === 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.storage") }}</span>
                              </v-tooltip>
                            </v-overlay>
                            <v-overlay
                              v-if="!disableByPackage && status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.permission") }}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-expand-transition>
                          <template
                            v-if="
                              !disableByPackage && !disableDataStorageOverload
                            "
                          >
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-27px;left:-145px;"
                              :value="show_download_all"
                              :content="$t('balloon.download_all')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-27px;left:-125px;"
                              :value="show_download_all"
                              :content="$t('balloon.download_all')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </template>
                        </v-list-item>
                      </template>
                      <span>{{ $t("myinboxPage.storageisfull") }}</span>
                    </v-tooltip>
                    <!-- @click="fn_setheader('csv')" -->
                    <v-tooltip
                      bottom
                      content-class="mt-n13"
                      v-model="showToolTipExportFilecsv"
                      :disabled="!disableDataStorageOverload"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          @click="
                            disableByPackage || disableDataStorageOverload || status_permission_outbox !=='AD'  && status_permission_outbox !== 'DL'
                              ? ''
                              : exportfilecsv(),
                              (closeOnContentClick = true)
                          "
                          outlined
                          v-on="on"
                          :disabled="disableByPackage || status_permission_outbox !=='AD' && status_permission_outbox !== 'DL'"
                          :ripple="!disableDataStorageOverload"
                          @mouseover="status_permission_outbox !=='AD' && status_permission_outbox !== 'DL' ? show_download_all_csv = false :  show_download_all_csv = true"
                          @mouseleave="show_download_all_csv = false"
                        >
                          <v-list :disabled="disableDataStorageOverload">
                            <v-list-item-title>
                              <v-icon left :color="color.theme"
                                >insert_drive_file</v-icon
                              >
                              {{
                                "&nbsp;&nbsp;&nbsp;" +
                                  $t("myinboxPage.exportcsv")
                              }}
                            </v-list-item-title>
                          </v-list>
                          <v-expand-transition>
                            <v-overlay
                              v-if="disableByPackage && status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.storage_and_permission") }}</span>
                              </v-tooltip>
                            </v-overlay>
                            <v-overlay
                              v-else-if="disableByPackage && status_permission_outbox === 'AD' && status_permission_outbox === 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.storage") }}</span>
                              </v-tooltip>
                            </v-overlay>
                            <v-overlay
                              v-if="!disableByPackage && status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.permission") }}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-expand-transition>
                          <template
                            v-if="
                              !disableByPackage && !disableDataStorageOverload
                            "
                          >
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-27px;left:-197px;"
                              :value="show_download_all_csv"
                              :content="$t('balloon.download_all_csv')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-27px;left:-175px;"
                              :value="show_download_all_csv"
                              :content="$t('balloon.download_all_csv')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </template>
                        </v-list-item>
                      </template>
                      <span>{{ $t("myinboxPage.storageisfull") }}</span>
                    </v-tooltip>
                    <!-- @click="fn_setheader('excel')"  -->
                    <v-tooltip
                      bottom
                      content-class="mt-n13"
                      v-model="showToolTipExportFileExcel"
                      :disabled="!disableDataStorageOverload"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          @click="
                            disableByPackage || disableDataStorageOverload || status_permission_outbox !=='AD' && status_permission_outbox !=='DL'
                              ? ''
                              : exportfileexcel(),
                              (closeOnContentClick = true)
                          "
                          outlined
                          v-on="on"
                          :disabled="disableByPackage || status_permission_outbox !=='AD' &&  status_permission_outbox !=='DL'"
                          :ripple="!disableDataStorageOverload"
                          @mouseover="show_download_all_excel = true"
                          @mouseleave="show_download_all_excel = false"
                        >
                          <v-list :disabled="disableDataStorageOverload">
                            <v-list-item-title>
                              <v-icon left :color="color.theme"
                                >description</v-icon
                              >
                              {{
                                "&nbsp;&nbsp;&nbsp;" +
                                  $t("myinboxPage.exportexcel")
                              }}
                            </v-list-item-title>
                          </v-list>
                          <v-expand-transition>
                            <v-overlay
                              v-if="disableByPackage && status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.storage_and_permission") }}</span>
                              </v-tooltip>
                            </v-overlay>
                            <v-overlay
                              v-else-if="disableByPackage && status_permission_outbox === 'AD' && status_permission_outbox === 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.storage") }}</span>
                              </v-tooltip>
                            </v-overlay>
                            <v-overlay
                              v-if="!disableByPackage && status_permission_outbox !== 'AD' && status_permission_outbox !== 'DL'"
                              absolute
                              color="grey lighten-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.permission") }}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-expand-transition>
                          <template
                            v-if="
                              !disableByPackage && !disableDataStorageOverload
                            "
                          >
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-27px;left:-210px;"
                              :value="show_download_all_excel"
                              :content="$t('balloon.download_all_excel')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-27px;left:-190px;"
                              :value="show_download_all_excel"
                              :content="$t('balloon.download_all_excel')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </template>
                        </v-list-item>
                      </template>
                      <span>{{ $t("myinboxPage.storageisfull") }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
                <!-- <v-btn
                  class="ma-1"
                  outlined
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfile()"
                >
                  <v-icon left>mdi-archive-arrow-down</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.export')}}
                </v-btn>
                <v-btn
                  class="ma-1"
                  outlined
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfilecsv()"
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.exportcsv')}}
                </v-btn>
                <v-btn
                  class="ma-1"
                  outlined
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfileexcel()"
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.exportexcel')}}
                </v-btn> -->
                <!-- ลบจาก Filter -->
                <v-btn
                  v-if ="status_permission_outbox === 'AD' && check_import_search !== true && panel !== 1"
                  class="ma-1 white--text"
                  color="#C51D1D"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="delete_filter()"
                >
                  <v-icon left>mdi-trash-can-outline</v-icon>
                  {{ $t("myoutboxPage.delete_filter") }}
                </v-btn>
              </v-flex>

              <v-flex xs12 lg12 class="ma-4">
                <v-layout row wrap class="pl-3 pr-3">
                  <v-flex xs4 lg2>
                    <v-select
                      class="pr-2"
                      prepend-inner-icon="sort"
                      hide-details
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      item-text="value"
                      item-disabled="disable"
                      @change="clickpagination()"
                    >
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                          :item-color="color.theme"
                          :ripple="!item.disable"
                        >
                          {{ item.value }}
                          <v-expand-transition>
                            <v-overlay
                              absolute
                              color="grey lighten-1"
                              :value="item.disable"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.lock_function") }}</span>
                              </v-tooltip>
                            </v-overlay>
                          </v-expand-transition>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs4 lg2>
                    <v-select
                      class="pr-2"
                      @change="clickstatus(statusde)"
                      v-model="statusde"
                      hide-details
                      solo
                      dense
                      :items="liststatus"
                    >
                    </v-select>
                  </v-flex>
                  <!-- เพิ่ม Relation -->
                  <!-- <v-flex xs12 lg2>
                    <v-btn
                    @click="openreletion=true"
                    :color="color.theme"
                    :dark="color.darkTheme"
                    >
                   {{ $t('myoutboxPage.add_relation')}}
                    </v-btn>
                  </v-flex> -->
                  <!-- ปิดชื่อฟิลเตอร์ตามหัวข้อสีเขียว -->
                  <!-- <v-flex
                    lg4
                    offset-lg2
                    class="pl-3 pr-2 hidden-md-and-down"
                    v-if="sortby === 'status'"
                  >
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSortorderby"
                      :color="color.alertSort"
                      dark
                      v-show="clickSortorderby"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon
                          :color="color.alertSort"
                          v-if="checkpagination === true"
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          :color="color.alertSort"
                          v-else-if="checkpagination === false"
                          >mdi-chevron-down</v-icon
                        >
                        <span :style="colorSort" v-if="sortby === 'status'">{{
                          $t("Filter.status")
                        }}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="closeclicksort"
                        >
                          <v-icon :color="color.alertSort"
                            >mdi-close-circle</v-icon
                          >
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                  <v-flex
                    lg4
                    offset-lg2
                    class="pl-3 pr-2 hidden-md-and-down"
                    v-else
                  >
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSort"
                      :color="color.alertSort"
                      dark
                      v-show="clickSort"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon
                          :color="color.alertSort"
                          v-if="multiSort === true"
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          :color="color.alertSort"
                          v-else-if="multiSort === false"
                          >mdi-chevron-down</v-icon
                        >
                        <span :style="colorSort" v-if="sortby === 'name'">{{
                          $t("Filter.nameonly")
                        }}</span>
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'docdate'"
                          >{{ $t("Filter.docdate") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'docno'"
                          >{{ $t("Filter.docno") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'outboxdate'"
                          >{{ $t("Filter.outboxdate") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'doctype'"
                          >{{ $t("Filter.doctype") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'receivername'"
                          >{{ $t("Filter.receivername") }}</span
                        >
                        <span
                          :style="colorSort"
                          v-else-if="sortby === 'sendername'"
                          >{{ $t("Filter.sendname") }}</span
                        >
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="checkSort"
                        >
                          <v-icon :color="color.alertSort"
                            >mdi-close-circle</v-icon
                          >
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex> -->
                </v-layout>
                <v-layout>
                  <!-- ปุ่ม Menu อันเก่า -->
                  <v-flex xs4 lg4>
                    <!--<div v-if="statusmutipledownload === false" class="pt-2">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        :color="color.theme"
                        rounded
                        :dark="color.darkTheme"
                        class="ma-1"
                        fab
                        small
                        @click="statusmutipledownload = true,show_menu = false"
                      >
                        <v-icon>apps</v-icon>&nbsp;
                        {{ $t('myoutboxPage.multipledownload')}} ไม่ใช้
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_menu"
                          content="Menu"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <v-btn 
                      @mouseover="show_download = true"
                      @mouseleave="show_download = false"
                      fab 
                      small 
                      color="primary" 
                      class="ma-1" 
                      @click="multipledownload()">
                        <v-icon>mdi-download-multiple</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_download"
                          content="Multiple Download"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      add delete button
                      <v-btn 
                      @mouseover="show_delete = true"
                      @mouseleave="show_delete = false"
                      fab 
                      small 
                      color="error" 
                      class="ma-1" 
                      @click="multipleDelete()" v-if="status_permission_outbox === 'AD'">
                        <v-icon color="white">delete</v-icon>
                          <v-badge
                            style="z-index:10000;bottom:-45px;left:-60px;"
                            :value="show_delete"
                            content="Multiple Delete"
                            transition="slide-y-transition"
                          ></v-badge>
                      </v-btn>
                      <v-btn                      
                        @mouseover="show_print = true"
                        @mouseleave="show_print = false"
                        fab
                        small
                        color="#5875D3"
                        class="white--text ma-1 pa-0"
                        @click="fn_printPDF()"
                      >
                        <v-icon>print</v-icon>
                          <v-badge
                            style="z-index:10000;bottom:-45px;left:-40px;"
                            :value="show_print"
                            content="print PDF"
                            transition="slide-y-transition"
                          ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        :dark="color.darkTheme"
                        :color="color.theme"
                        class="ma-1"
                        @click="statusmutipledownload = false, clearmultipledownload(),show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                          <v-badge
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_clear"
                            content="Clear"
                            transition="slide-y-transition"
                          ></v-badge>
                      </v-btn>
                    </div>-->
                  </v-flex>
                  <v-flex xs8 lg8 class="text-right">
                    <!-- หน้าจอธรรมดา -->
                    <div v-if="resolutionScreen >= 400">
                      <v-chip
                        class="ma-2"
                        :color="color.theme"
                        :text-color="color.chipText"
                      >
                        <v-icon left>mdi-file</v-icon>
                        {{
                          $t("myinboxPage.search_file") +
                            " " +
                            totalfile +
                            " " +
                            $t("myinboxPage.items") ||
                            0 + " " + $t("myinboxPage.items")
                        }}
                      </v-chip>
                    </div>
                    <!-- หน้าจอมือถือ -->
                    <div v-else>
                      <v-chip
                        class="mt-5 px-1"
                        color="#5AB685"
                        style="height: 32px; border-radius: 32px;"
                        :text-color="color.chipText"
                      >
                        <v-icon center size="18" class="mr-2"
                          >mdi-file-document</v-icon
                        >
                        {{
                          $t("myinboxPage.search_file") +
                            " " +
                            totalfile +
                            " " +
                            $t("myinboxPage.items") ||
                            0 + " " + $t("myinboxPage.items")
                        }}
                      </v-chip>
                    </div>
                  </v-flex>
                </v-layout>
                <!-- Sort File -->
                <!-- <v-flex lg12 xs12> -->
                <!-- หน้าจอธรรมดา -->
                <!-- <div v-if="resolutionScreen >= 400">
                    <v-layout row wrap class="pl-3 pr-1" justify-end>
                      <v-flex lg8 xs8> </v-flex>
                      <v-flex lg4 offset-lg8 offset-xs4 class="pl-3 pr-0">
                        <v-list nav :color="color.BG" class="py-0">
                          <v-list-item>
                            <v-layout>
                              <v-flex class="text-right" lg12>
                                <v-menu offset-y rounded="lg">
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                      small
                                      rounded
                                      class="white--text"
                                      :color="color.theme"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>mdi-sort</v-icon>
                                      {{ $t("sorting.sort") }}:
                                      <div class="font-weight-bold">
                                        {{
                                          $t(
                                            sorting["sort"].find(
                                              (x) => x.id === sort_by
                                            ).text
                                          )
                                        }},
                                        {{
                                          $t(
                                            sorting["direction"].find(
                                              (x) => x.id === direction
                                            ).text
                                          )
                                        }}
                                      </div>
                                    </v-btn>
                                  </template>
                                  <v-list dense nav>
                                    <v-list-item-group
                                      v-model="sort_by"
                                      active-class="border"
                                      :color="color.theme"
                                    >
                                      <template
                                        v-for="(item, i) in sorting['sort']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                    <v-divider class="mt-2" />
                                    <v-list-item-group
                                      class="mt-4"
                                      v-model="direction"
                                      active-class="border"
                                      :color="color.theme"
                                    >
                                      <template
                                        v-for="(item, i) in sorting[
                                          'direction'
                                        ]"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </v-layout>
                  </div> -->
                <!-- หน้าจอมือถือ -->
                <!-- <div v-else>
                    <v-flex class="text-left" style="margin-top:-27px;">
                      <v-menu offset-y rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            x-small
                            rounded
                            :color="color.theme"
                            v-bind="attrs"
                            v-on="on"
                            text
                            style="font-size:16px; letter-spacing: 0px;"
                            class="ml-3"
                          >
                            <span>{{ $t("sorting.sort") }} : </span>
                            <span class="font-weight-bold">
                              {{
                                $t(
                                  sorting["sort"].find((x) => x.id === sort_by)
                                    .text
                                )
                              }}
                            </span>
                            <v-icon size="20">{{
                              $t(
                                sorting["direction"].find(
                                  (x) => x.id === direction
                                ).text
                              ) === $t("sorting.ascending")
                                ? "mdi-arrow-up"
                                : "mdi-arrow-down"
                            }}</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense nav>
                          <v-list-item-group
                            v-model="sort_by"
                            active-class="border"
                            :color="color.theme"
                          >
                            <template v-for="(item, i) in sorting['sort']">
                              <v-list-item :key="`item-${i}`" :value="item.id">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t(item.text)
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                          <v-divider class="mt-2" />
                          <v-list-item-group
                            class="mt-4"
                            v-model="direction"
                            active-class="border"
                            :color="color.theme"
                          >
                            <template v-for="(item, i) in sorting['direction']">
                              <v-list-item :key="`item-${i}`" :value="item.id">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t(item.text)
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </div>
                </v-flex> -->
              </v-flex>

              <v-flex xs12 lg12 class="ma-4" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular
                    :size="50"
                    :color="color.theme"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-flex>
              <v-flex xs11 lg12 v-else class="ma-4">
                <div
                  class="d-flex justify-end mb-4 mt-n4 px-1"
                  v-if="$route.query.type === '2'"
                >
                  <div v-if="dataAccountActive.business_info.business_feature.filter(item => item.feature === 'Rename document').length > 0">
                    <v-btn
                      v-if="dataAccountActive.business_info.business_feature.filter(item => item.feature === 'Rename document')[0].feature_status === 'Y'"
                      small
                      rounded
                      dark
                      color="#33691E"
                      @click="opendialogmanagerenamedocumentinbox = true"
                      ><v-icon small left>mdi-file-document-edit</v-icon>{{ $t("renamedocument.renamedocumentheader")}}</v-btn
                    >
                  </div>
                </div>
                <div v-if="resolutionScreen <= 500">
                  <v-list
                    two-line
                    class="mb-6 pb-6 elevation-1"
                    v-if="outboxdata.length !== 0"
                  >
                    <v-list-item v-if="outboxdata.length === 0">
                      <v-list-item-content class="text-center">
                        <v-list-item-title
                          v-text="$t('myoutboxPage.Nodata')"
                        ></v-list-item-title>
                        <v-list-item-subtitle></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="item in outboxdata"
                      :key="item.title"
                      style="cursor: pointer;"
                    >
                      <v-list-item-avatar v-show="statusmutipledownload">
                        <v-checkbox
                          v-model="filemultipledownload"
                          :value="item"
                          v-if="
                            item.trash !== 'DELETE' && item.data_type !== null
                          "
                        />
                      </v-list-item-avatar>
                      <v-list-item-avatar>
                        <v-icon
                          large
                          :color="seticon_new(item.data_type)[1]"
                          style="font-weight: 100"
                          >{{ seticon_new(item.data_type)[0] }}</v-icon
                        >
                      </v-list-item-avatar>

                      <v-list-item-content @click="previewfile(item)">
                        <v-list-item-title
                          v-text="item.filename"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.document_no"
                        ></v-list-item-subtitle>
                        <div>
                          <!-- <v-chip
                            v-if="item.status === 'receiver_denied_request'"
                            x-small
                            dark
                            color="red"
                            >ปฎิเสธคำขอลบ</v-chip
                          > -->
                          <!-- :color="
                              item.status === 'pending'
                                ? '#ffcc00'
                                : item.status === 'reject'
                                ? 'red'
                                : item.status === 'unsend'
                                ? '#ff8533'
                                : item.status === 'duplicate'
                                ? 'primary'
                                : item.status === 'request_delete_log'
                                ? '#FF0000'
                                : item.status === 'pending_receiver_stoage_full'
                                ? '#ffcc00'
                                : item.status === 'resend_from_etax'
                                ? '#fc9208e6'
                                : 'success'
                            " -->
                          <v-chip
                            x-small
                            dark
                            :color="
                              item.status === 'pending'
                                ? '#ffcc00'
                                : item.status === 'duplicate'
                                ? 'primary'
                                : item.status === 'resend_from_etax'
                                ? '#6fbf72'
                                : item.status === 'sender_delete'
                                ? 'error'
                                : item.status === 'request_delete_log'
                                ? 'error'
                                : item.status === 'reject'
                                ? 'warning'
                                : 'success'
                            "
                          >
                            {{ $t("status_inbox_outbox." + item.status) }}
                            <!-- {{
                              item.status === "request_delete_log"
                                ? "ขอลบ"
                                : item.status === "resend_from_etax"
                                ? "ส่งซ้ำจาก etax"
                                : item.status
                            }} -->
                          </v-chip>

                          <v-icon
                            v-if="item.trash === 'DELETE'"
                            color="red"
                            small
                          >
                            cancel_presentation
                          </v-icon>
                          <v-icon
                            v-else-if="item.trash === 'T'"
                            color="amber"
                            small
                          >
                            delete_sweep
                          </v-icon>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              v-on="on"
                              outlined
                              :disabled="item.data_type === null"
                            >
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="previewfile(item)">
                              <v-list-item-icon>
                                <v-icon>visibility</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  $t("currentRClick.preview")
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="(opendialog = true), (newdata = item)"
                              :disabled="disableDataStorageOverload"
                            >
                              <v-list-item-icon>
                                <v-icon>
                                  mdi-information
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{
                                    $t("myoutboxPage.buttonAction.fileDetail")
                                  }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- @click="openoutbox = true, newdata=item" -->
                            <!-- <v-list-item  @click="openoutbox = true, newdata=item">
                                <v-list-item-icon>
                                  <v-icon>
                                    folder
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('myoutboxPage.buttonAction.relatedFile')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
                            <!-- @click="disableByPackage ? '' : downloadfile(item)" 
                            :ripple="!disableByPackage" 
                              :disabled="disableByPackage" -->

                            <v-list-item
                              v-if="(item.trash === 'T' || item.trash === 'alive') && status_permission_outbox === 'AD' || status_permission_outbox === 'DL'"                              
                              @click="downloadfile(item)"
                              :disabled="disableDataStorageOverload"
                            >
                              <v-list-item-icon>
                                <v-icon>
                                  mdi-download-multiple
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t("myoutboxPage.buttonAction.download") }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <!-- <v-expand-transition>
                                  <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                                    <v-icon>mdi-lock</v-icon>
                                  </v-overlay>
                                </v-expand-transition> -->
                            </v-list-item>
                            <!-- ลบลงถังขยะ -->
                            <v-list-item
                              @click="fn_confirmdelete(item)"
                              v-if="status_permission_outbox === 'AD'"
                            >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>ลบ</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- ยกเลิกคำขอลบ -->
                            <v-list-item
                              @click="fn_canceldeleterequest(item)"
                              v-if="item.status === 'receiver_denied_request'"
                            >
                              <v-list-item-icon>
                                <v-icon>cancel</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >ยกเลิกคำขอลบ</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='N'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                            <v-list-item
                              @click="
                                (opendialogdelete = true),
                                  (data = item),
                                  (statusdelete = 'Y')
                              "
                              v-if="
                                status_permission_outbox === 'AD' &&
                                  item.status !== 'receiver_denied_request'
                              "
                            >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  $t("checkdelete.deleteandrequest")
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- ยืนยัน request_delete_log -->
                            <v-list-item
                              v-if="item.status === 'request_delete_log'"
                            >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="pr-2">
                                <v-btn
                                  color="green"
                                  dark
                                  dense
                                  @click="
                                    (opendialogrequestdelete = true),
                                      (data = item),
                                      (statusrequestdelete = 'Y')
                                  "
                                  >ยืนยัน</v-btn
                                >
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-btn
                                  color="red"
                                  dark
                                  dense
                                  @click="deleterequest(item, 'N')"
                                  >ไม่ยืนยัน</v-btn
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <!-- ตั้งค่าเวลาหมดอายุ -->
                            <!-- <v-list-item @click="fn_openset(), (newdata = item)">
                                <v-list-item-icon>
                                   <v-icon>mdi-folder-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                   ตั้งค่าวันหมดอายุ
                                </v-list-item-content>
                              </v-list-item>
                             <v-list-item v-if="item.trash !== 'DELETE'  &&  item.trash !== 'T' && item.status !== 'request' && item.status !== 'unsend'">
                              <v-list-item-icon>
                                <v-icon>
                                  sync_alt
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="buntonRequest(item)" 
                                  color="primary"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Request
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item> -->
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- 
                    <v-divider inset></v-divider>-->
                  </v-list>
                  <v-list v-else class="elevation-1">
                    <v-list-item>
                      <!-- หน้าจอธรรมดา -->
                      <v-list-item-content v-if="resolutionScreen >= 500">
                        <v-list-item-title
                          class="text-center"
                          v-text="$t('tablefile.empty')"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <!-- หน้าจอมือถือ -->
                      <v-list-item-content v-else>
                        <v-card-text class="mt-4 mb-4">
                          <v-img
                            :src="require('../assets/img/icon_empty_data.png')"
                            max-width="132"
                            max-height="150"
                            class="mx-auto"
                          />
                          <v-list-item-title
                            class="text-center mt-8"
                            v-text="$t('myoutboxPage.Nodata')"
                            :style="
                              'font-size: 16px; font-weight: 700; line-height: 24px; color:' +
                                color.theme +
                                ';'
                            "
                          ></v-list-item-title>
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div v-else>
                  <v-layout class="mb-2">
                    <div class="ml-2 d-flex">
                      <div v-if="statusmutipledownload === false" class="pt-0">
                        <v-btn
                          @mouseover="show_menu = true"
                          @mouseleave="show_menu = false"
                          :disabled="sortStatus === 'pending'"
                          :color="color.theme"
                          rounded
                          class="ma-1 white--text"
                          @click="
                            (statusmutipledownload = true), (show_menu = false), (arrayfileidselect = [])
                          "
                        >
                          <v-icon>mdi-menu</v-icon>&nbsp;
                          <p
                            class="ml-2"
                            style="font-size:15px;margin-bottom:2px;"
                          >
                            {{ $t("balloon.menu") }}
                          </p>
                          <!-- {{ $t('myoutboxPage.multipledownload')}} -->
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-40px;"
                            :value="show_menu"
                            :content="$t('balloon.menu')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-55px;"
                            :value="show_menu"
                            :content="$t('balloon.menu')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                      </div>
                      <div v-else class="pt-2">
                        <!-- :color="disableDataStorageOverload ? '#D6D6D6' : 'primary'" 
                        @click="disableDataStorageOverload || disableByPackage ? '' : multipledownload()"
                        :ripple="!disableDataStorageOverload || !disableByPackage" -->
                        <v-btn
                         v-if="status_permission_outbox === 'AD' || status_permission_outbox === 'DL'"
                          @mouseover="show_download = true"
                          @mouseleave="show_download = false"
                          fab
                          small
                          class="white--text ma-1"
                          :color="
                            disableDataStorageOverload ? '#D6D6D6' : 'primary'
                          "
                          @click="
                            disableDataStorageOverload ? '' : multipledownload()
                          "
                          :ripple="!disableDataStorageOverload"
                        >
                          <v-icon :disabled="disableDataStorageOverload"
                            >mdi-download-multiple</v-icon
                          >
                          <!-- <v-icon>mdi-download-multiple</v-icon> -->
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_download"
                            :content="
                              disableDataStorageOverload
                                ? $t('myinboxPage.storageisfull')
                                : $t('balloon.download')
                            "
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_download"
                            :content="
                              disableDataStorageOverload
                                ? $t('myinboxPage.storageisfull')
                                : $t('balloon.download')
                            "
                            transition="slide-y-transition"
                          ></v-badge>
                          <!-- <v-expand-transition>
                          <v-overlay absolute color="grey lighten-1" style="border-radius: 50%; width: 100%; height: 160%; margin-top: -7px;" :value="disableByPackage">
                            <v-icon>mdi-lock</v-icon>
                          </v-overlay>
                        </v-expand-transition>                         -->
                        </v-btn>
                        <!-- add delete button -->
                        <v-btn
                          @mouseover="show_delete = true"
                          @mouseleave="show_delete = false"
                          fab
                          small
                          color="error"
                          class="ma-1"
                          @click="multipleDelete()"
                          v-if="status_permission_outbox === 'AD'"
                        >
                          <v-icon color="white">delete</v-icon>
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-22px;"
                            :value="show_delete"
                            :content="$t('balloon.delete')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-34px;"
                            :value="show_delete"
                            :content="$t('balloon.delete')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                        <v-btn
                          v-if="status_permission_outbox === 'AD' || status_permission_outbox === 'DL'"
                          @mouseover="show_print = true"
                          @mouseleave="show_print = false"
                          fab
                          small
                          :color="
                            disableDataStorageOverload ? '#D6D6D6' : '#5875D3'
                          "
                          class="white--text ma-1 pa-0"
                          @click="
                            disableDataStorageOverload ? '' : fn_printPDF()
                          "
                          :ripple="!disableDataStorageOverload"
                        >
                          <v-icon :disabled="disableDataStorageOverload"
                            >print</v-icon
                          >
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-38px;"
                            :value="show_print"
                            :content="
                              disableDataStorageOverload
                                ? $t('myinboxPage.storageisfull')
                                : $t('balloon.print')
                            "
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-42px;"
                            :value="show_print"
                            :content="
                              disableDataStorageOverload
                                ? $t('myinboxPage.storageisfull')
                                : $t('balloon.print')
                            "
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                        <v-btn
                          @mouseover="show_clear = true"
                          @mouseleave="show_clear = false"
                          fab
                          small
                          :dark="color.darkTheme"
                          :color="color.theme"
                          class="ma-1"
                          @click="
                            (statusmutipledownload = false),
                              clearmultipledownload(),
                              (show_clear = false)
                          "
                        >
                          <v-icon>clear</v-icon>
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-20px;"
                            :value="show_clear"
                            :content="$t('balloon.clear')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_clear"
                            :content="$t('balloon.clear')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                      </div>
                    </div>

                    <v-flex d-flex justify-center>
                      <v-pagination
                        class="mr-14"
                        v-model="page"
                        :length="pageCount"
                        :color="color.theme"
                        :total-visible="10"
                        @input="clickpagination()"
                      ></v-pagination>
                    </v-flex>

                    <div
                      class="d-flex justify-end mb-4 px-1"
                      v-if="$route.query.type === '2'"
                    >
                      <v-btn
                        small
                        rounded
                        dark
                        :color="color.theme"
                        @click="opendialogmanageheader = true"
                        >{{ $t("myinboxPage.settingHeader.setting") }}</v-btn
                      >
                    </div>
                  </v-layout>
                  <!-- panigation อันเก่า -->
                  <!-- <div class="text-xs-center ma-2">
                    <br />
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div> -->
                  <v-data-table
                    v-model="filemultipledownload"
                    :headers="headers"
                    :items="outboxdata"
                    item-key="file_id"
                    :search="search"
                    class="elevation-1"
                    hide-default-footer
                    :single-select="false"
                    :show-select="statusmutipledownload"
                    :page.sync="page"
                    :items-per-page="size"
                    @page-count="pageCount = $event"
                    :no-data-text='$t("myoutboxPage.Nodata")'
                  >
                    <!-- <template v-slot:[`header.data_type`]="{ header }">
                    <span :style="headerTable" 
                    @click="sortby='status',clickSort=true,multiSort=!multiSort,sortFile(outboxdata) "
                    >{{header.text}}</span>
                    </template> -->
                    <!-- @click="sortby='status',clickSortorderby=true ,checkpagination=!checkpagination ,check(),sortFile(outboxdata)" -->
                    <template v-slot:[`header.data_type`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ header.text }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        :style="headerTable"
                        @click="
                          (sortby = 'status'),
                            (clickSortorderby = true),
                            (multiSort = !multiSort),
                            (checkpagination = !checkpagination),
                            sortFile(outboxdata)
                        "
                        >{{ header.text }}</span
                      > -->
                    </template>
                    <template v-slot:[`header.filename`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'name'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                    </template>
                    <template v-slot:[`header.document_no`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'docno'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                    </template>
                    <template v-slot:[`header.PO`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.document_date`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'docdate'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                    </template>
                    <template v-slot:[`header.document_type`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'doctype'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                    </template>
                    <!-- HEADER TABLE -->
                    <!-- <template v-slot:header.sender_name="{ header }">
                      <span
                        class="pointer"
                        @click="sortby='sendername', clickSort=true,multiSort=!multiSort,sortFile(outboxdata)  "
                        :style="headerTable"
                      >{{ $t(header.text) }}</span>
                    </template>-->
                    <template v-slot:[`header.receiver_name`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'receivername'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                    </template>
                    <template v-slot:[`header.cre_dtm`]="{ header }">
                      <span
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                      <!-- คลิก Sort File จากหัวข้อ -->
                      <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'outboxdate'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                    </template>
                    <template v-slot:[`header.status_onechat`]="{ header }">
                      <span
                        class="pointer"
                        @click="
                          (sortby = 'outboxdate'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(outboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <!-- <template v-slot:[`header.resendmail`]="{ header }">
                      <span
                        class="pointer"
                        @click="sortby='outboxdate', clickSort=true,multiSort=!multiSort,sortFile(outboxdata)  "
                        :style="headerTable"
                      >{{ $t(header.text) }}</span>
                    </template> -->
                    <template v-slot:[`header.file_id`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                    </template>

                    <template v-slot:item="props">
                      <tr 
                        @dblclick="previewfile(props.item)"
                        :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                        @click="setSelectitemtable(props.item)"
                      >
                        <td
                          width="1%"
                          class="text-center"
                          v-show="statusmutipledownload"
                        >
                          <v-checkbox
                            v-model="filemultipledownload"
                            :value="props.item"
                            v-if="
                              props.item.trash !== 'DELETE' &&
                                props.item.data_type !== null
                            "
                          />
                        </td>
                        <td width="5%" class="text-center">
                          <!-- style="display:flex" -->
                          <div>
                            <v-badge
                              color="red"
                              overlap
                              icon="clear"
                              small
                              v-if="props.item.trash === 'DELETE'"
                            >
                              <v-icon
                                large
                                :color="seticon_new(props.item.data_type)[1]"
                                >{{
                                  seticon_new(props.item.data_type)[0]
                                }}</v-icon
                              >
                            </v-badge>
                            <v-badge
                              color="amber"
                              overlap
                              icon="delete_sweep"
                              small
                              v-else-if="props.item.trash === 'T'"
                            >
                              <v-icon
                                large
                                :color="seticon_new(props.item.data_type)[1]"
                                >{{
                                  seticon_new(props.item.data_type)[0]
                                }}</v-icon
                              >
                            </v-badge>
                            <v-icon
                              v-else
                              large
                              :color="seticon_new(props.item.data_type)[1]"
                              >{{
                                seticon_new(props.item.data_type)[0]
                              }}</v-icon
                            ><br />
                            <!-- <v-chip
                              v-if="props.item.status === 'receiver_denied_request'"
                              x-small
                              dark
                              color="red"
                            >ปฎิเสธคำขอลบ</v-chip> -->
                            <!-- props.item.status === 'pending'
                              ? '#ffcc00'
                              : props.item.status === 'reject'
                              ? 'red'
                              : props.item.status === 'unsend'
                              ? '#ff8533'
                              : props.item.status === 'duplicate'
                              ? 'primary'
                              : props.item.status === 'request_delete_log'
                              ? 'amber'
                              : props.item.status ===
                                'pending_receiver_stoage_full'
                              ? '#ffcc00'
                              : props.item.status === 'resend_from_etax'
                              ? '#fc9208e6'
                              : 'success' -->
                            <v-chip
                              class="ma-1"
                              x-small
                              dark
                              :color="
                                props.item.status === 'pending'
                                  ? '#ffcc00'
                                  : props.item.status === 'duplicate'
                                  ? 'primary'
                                  : props.item.status === 'resend_from_etax'
                                  ? '#6fbf72'
                                  : props.item.status === 'sender_delete'
                                  ? 'error'
                                  : props.item.status === 'request_delete_log'
                                  ? 'error'
                                  : props.item.status === 'reject'
                                  ? 'warning'
                                  : 'success'
                              "
                            >
                              {{ $t("status_inbox_outbox." + props.item.status) }}
                              <!-- {{
                                props.item.status === "request_delete_log"
                                  ? "ส่งคำขอลบ"
                                  : props.item.status === "resend_from_etax"
                                  ? "ส่งซ้ำจาก etax"
                                  : props.item.status === "reject"
                                  ? "ปฏิเสธคำขอลบ"
                                  : props.item.status
                              }} -->
                            </v-chip>
                          </div>
                        </td>
                        <td
                          width="20%"
                          :title="props.item.filename"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.filename === 'Y'
                          "
                        >
                          {{ props.item.filename | subStr }}
                        </td>
                        <td
                          width="10%"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.document_no === 'Y'
                          "
                        >
                          {{ props.item.document_no | subStr }}
                        </td>
                        <td
                          width="10%"
                          class="text-center"
                          v-if="feature.PO === 'Y'"
                        >
                        {{ props.item.po_number === '' || props.item.po_number === null ? "-" : props.item.po_number}}
                        </td>
                        <td
                          width="10%"
                          class="text-center"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.document_date === 'Y'
                          "
                        >
                          {{ setformatdate(props.item.document_date) }}
                          <!-- <v-icon v-if="props.item.expired_inbox !== null " color="error">
                            mdi-folder-clock
                          </v-icon> -->
                        </td>
                        <td
                          width="5%"
                          class="text-center"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.document_type_code === 'Y'
                          "
                        >
                          <v-chip
                            Default
                            :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
                            >{{ props.item.document_type }}</v-chip
                          >
                        </td>
                        <td
                          width="10%"
                          class="text-center"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.buyer_name === 'Y'
                          "
                        >
                          <v-chip
                            Default
                            :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
                            >{{ props.item.receiver_name }}</v-chip
                          >
                        </td>
                        <!-- <td width="10%" class="text-center">{{props.item.sender_name}}</td> -->
                        <td
                          width="10%"
                          class="text-center"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.cre_dtm === 'Y'
                          "
                        >
                          {{ formatdatetime(props.item.cre_dtm) }}
                        </td>
                        <td
                          width="10%"
                          class="text-center"
                          v-if="
                            dataAccountActive.type === 'Citizen' ||
                              feature.status_onechat === 'Y'
                          "
                        >
                          <v-chip
                            class="ma-2"
                            :color="
                              props.item.status_onechat === '' ||
                              props.item.status_onechat === '0'
                                ? '#BEBEBE'
                                : props.item.status_onechat === '1'
                                ? '#007bff'
                                : props.item.status_onechat === '2'
                                ? '#FFCC33'
                                : props.item.status_onechat === '3'
                                ? '#28a745'
                                : '#BEBEBE'
                            "
                            label
                            text-color="white"
                            >{{
                              props.item.status_onechat === "" ||
                              props.item.status_onechat === "0"
                                ? "ไม่ส่ง"
                                : props.item.status_onechat === "1"
                                ? "รอส่ง"
                                : props.item.status_onechat === "2"
                                ? "ส่งแล้ว"
                                : props.item.status_onechat === "3"
                                ? "อ่านแล้ว"
                                : "ไม่ส่ง"
                            }}</v-chip
                          >
                        </td>
                        <!-- <td width="10%" class="text-center" ><v-btn class="elevation-0" fab small @click="fn_openresendmail(props.item)"><v-icon>mdi-email</v-icon></v-btn></td> -->
                        <td width="5%" class="text-center">
                          <v-menu top offset-y>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                v-on="on"
                                outlined
                                :disabled="props.item.data_type === null"
                                @click="setSelectitemtable(props.item)"
                              >
                                <!-- @click="downloadfile(props.item) -->
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="previewfile(props.item)">
                                <v-list-item-icon>
                                  <v-icon>visibility</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("currentRClick.preview")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-tooltip
                                bottom
                                attach
                                content-class="mt-n14"
                                v-model="showToolTipFileDetail"
                                :disabled="!disableDataStorageOverload"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-list-item
                                    @click="
                                      disableDataStorageOverload
                                        ? ''
                                        : (opendialog = true),
                                        (newdata = props.item)
                                    "
                                    :ripple="!disableDataStorageOverload"
                                    v-on="on"
                                  >
                                    <v-list-item
                                      class="pa-0"
                                      :disabled="disableDataStorageOverload"
                                    >
                                      <v-list-item-icon>
                                        <v-icon>
                                          mdi-information
                                        </v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{
                                            $t(
                                              "myoutboxPage.buttonAction.fileDetail"
                                            )
                                          }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item>
                                </template>
                                <span>{{
                                  $t("myinboxPage.storageisfull")
                                }}</span>
                              </v-tooltip>
                              <!-- @click="openoutbox = true, newdata=props.item" -->
                              <!-- <v-list-item @click="openoutbox = true, newdata=props.item">
                                <v-list-item-icon>
                                  <v-icon>
                                    folder
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('myoutboxPage.buttonAction.relatedFile')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
                              <!-- :disabled="!disableDataStorageOverload" -->
                              <v-tooltip
                                bottom
                                attach
                                content-class="mt-n14"
                                v-model="showToolTipDownloadFile"
                                :disabled="!disableDataStorageOverload"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-list-item
                                    v-if="(props.item.trash === 'T' || props.item.trash === 'alive') && status_permission_outbox === 'AD' || status_permission_outbox === 'DL'"
                                    v-on="on"
                                    @click="
                                      disableDataStorageOverload
                                        ? ''
                                        : downloadfile(props.item)
                                    "
                                    :ripple="!disableDataStorageOverload"
                                  >
                                    <v-list-item
                                      class="pa-0"
                                      :disabled="disableDataStorageOverload"
                                    >
                                      <v-list-item-icon>
                                        <v-icon>
                                          mdi-download-multiple
                                        </v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{
                                            $t(
                                              "myoutboxPage.buttonAction.download"
                                            )
                                          }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item>
                                </template>
                                <span>{{
                                  $t("myinboxPage.storageisfull")
                                }}</span>
                              </v-tooltip>
                              <!-- ลบลงถังขยะ -->
                              <v-list-item
                                @click="fn_confirmdelete(props.item)"
                                v-if="status_permission_outbox === 'AD'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("checkdelete.delete")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- ยกเลิกคำขอลบ -->
                              <v-list-item
                                @click="fn_canceldeleterequest(props.item)"
                                v-if="
                                  props.item.status ===
                                    'receiver_denied_request'
                                "
                              >
                                <v-list-item-icon>
                                  <v-icon>cancel</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >ยกเลิกคำขอลบ</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true,data=props.item,statusdelete='N'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-tooltip
                                bottom
                                attach
                                content-class="mt-n14"
                                v-model="showToolTipDeleteForever"
                                :disabled="!disableDataStorageOverload"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-list-item
                                    v-if="
                                      props.item.status !==
                                        'request_delete_log' &&
                                        status_permission_outbox === 'AD' &&
                                        props.item.status !==
                                          'receiver_denied_request'
                                    "
                                    @click="
                                      disableDataStorageOverload
                                        ? ''
                                        : (opendialogdelete = true),
                                        (data = props.item),
                                        (statusdelete = 'Y')
                                    "
                                    :ripple="!disableDataStorageOverload"
                                    v-on="on"
                                  >
                                    <v-list-item class="pa-0">
                                      <v-list-item-icon>
                                        <v-icon>delete_forever</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{
                                          $t("checkdelete.deleteandrequest")
                                        }}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item>
                                </template>
                                <span>{{
                                  $t("myinboxPage.storageisfull")
                                }}</span>
                              </v-tooltip>
                              <!-- ยืนยันการขอลบ -->
                              <!-- <v-divider v-if="props.item.status === 'request_delete_log'"></v-divider>
                                  <v-list-item v-if="props.item.status === 'request_delete_log'">
                              <v-list-item-icon>
                                <v-icon>
                                  delete
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="opendialogrequestdelete = true ,data=props.item,statusrequestdelete='Y'" 
                                  color="green"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Delete
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                                <v-list-item-content class="pl-2">
                                  <v-btn @click="deleterequest(props.item,'N')" 
                                  color="red"
                                  dark
                                  >
                                    <v-list-item-title>
                                        No
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item> -->
                              <!-- ตั้งค่าเวลาหมดอายุ -->
                              <!-- <v-list-item @click="fn_openset(), (newdata = props.item)">
                              <v-list-item-icon>
                                <v-icon>mdi-folder-clock</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                 ตั้งค่าวันหมดอายุ
                              </v-list-item-content>
                            </v-list-item> -->
                              <!-- <v-divider></v-divider> -->
                              <!-- <v-list-item v-if="props.item.trash !== 'DELETE'  &&  props.item.trash !== 'T' && props.item.status !== 'request' && props.item.status !== 'unsend' && props.item.status !== 'request_delete_log'">
                              <v-list-item-icon>
                                <v-icon>
                                  sync_alt
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="buntonRequest(props.item)" 
                                  color="primary"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Request
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item> -->
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <!-- <template  v-slot:item="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @dblclick="previewfile(item)"
                          style="cursor: pointer;"
                        >
                        <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="item" />
                          </td>
                          <td class="text-center">
                            <v-icon
                              large
                              :color="seticon(item.data_type)[1]"
                            >{{ seticon(item.data_type)[0]}}</v-icon>
                          </td>
                          <td>{{ item.filename}}</td>
                          <td>{{ item.document_no}}</td>
                          <td class="text-center">{{ setformatdate(item.document_date)}}</td>
                          <td class="text-center">{{ item.document_type }}</td>
                          <td class="text-center">{{item.sender_name}}</td>
                          <td class="text-center">{{ formatdatetime(item.cre_dtm)}}</td>
                          <td class="text-center">
                            <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>-->
                  </v-data-table>
                </div>
                <div class="text-xs-center">
                  <br />
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="color.theme"
                    :total-visible="10"
                    @input="clickpagination()"
                  ></v-pagination>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
      <dialogcheckrequestdelete
        :show="opendialogrequestdelete"
        @closedialog="opendialogrequestdelete = false"
        @reload="getSourceSystem()"
        :data="data"
        :status="statusrequestdelete"
      ></dialogcheckrequestdelete>
      <dialogcheckdelete
        :show="opendialogdelete"
        @closedialog="opendialogdelete = false"
        @reload="getSourceSystem()"
        :data="data"
        :status="statusdelete"
      ></dialogcheckdelete>
      <outboxdetail
        :show="openoutbox"
        @closedialog="(openoutbox = false), getSourceSystem()"
        :fileoutbox="newdata"
        :filedata="outboxdata"
      />
      <dialogdetailoutbox
        :show="opendialog"
        @closedialog="opendialog = false"
        :fileoutbox="newdata"
      />
      <dialogedit
        :show="opendialogedit"
        :filedata="newdata"
        @closedialog="opendialogedit = false"
        @closedialogandrefresh="getSourceSystem(), (opendialogedit = false)"
      />
      <insertrelation
        :show="openreletion"
        :outboxdata="outboxdata"
        @closedialog="openreletion = false"
        @loaddata="getSourceSystem()"
      ></insertrelation>
      <dialogsnackbermultidowload
        :show="opensnackbarmultidownload"
        @closedialog="opensnackbarmultidownload = false"
        :percent="percent"
        :date="datetime"
        :namedowload="namedowload"
        :btsdownload="btsdownload"
        :count_loadingfile="count_loadingfile"
        :count_allfile="count_allfile"
        :statusloading="statusloading"
        :showtotalfileonly="showtotalfileonly"
      ></dialogsnackbermultidowload>
      <dialogmultideleteinboxoutbox
        :show="opensdialogmultidelinboxoutbox"
        @closedialog="
          (opensdialogmultidelinboxoutbox = false), (filemultipledownload = [])
        "
        @closedialogreload="
          (opensdialogmultidelinboxoutbox = false),
            (filemultipledownload = []),
            loadstorage(),
            getSourceSystem()
        "
        :filedata="filemultipledownload"
      ></dialogmultideleteinboxoutbox>
      <dialogcheckdeleterightclick
        :show="opensdialogcheckdeleterightclick"
        @closedialog="
          (opensdialogcheckdeleterightclick = false), (listdata = '')
        "
        @closedialogreload="
          (opensdialogcheckdeleterightclick = false),
            (listdata = ''),
            loadstorage(),
            getSourceSystem()
        "
        :filedata="listdata"
      ></dialogcheckdeleterightclick>
      <dialogconfirmdelete
        :filedata="listdata"
        :show="confirmdelete"
        @closedialog="confirmdelete = false"
        @reload="fn_deletetotrash(listdata)"
      />
      <dialogresendmail
        :filedata="listdata"
        :show="openresendmail"
        @closedialog="(openresendmail = false), (listdata = '')"
      />
      <dialogselectheader
        :show="opendialogselectheader"
        @closedialog="opendialogselectheader = false"
        @selectheaderbeforedownload="checkdownloadcsvandexcel"
      ></dialogselectheader>
      <dialogsnackbarloading_print
        :show="opensnackbarloadingprint"
        @closedialog="opensnackbarloadingprint = false"
        :percent="percent"
        :date="datetime"
      ></dialogsnackbarloading_print>
      <v-card class="elevation-0">
        <div :style="colorVapp">
          <previewfile
            :arrayfile="outboxdata"
            :show="openpreviewfile"
            :filedata_1="currentfile"
            @closepreviewfile="openpreviewfile = false"
            @openpreviewfile="openpreviewfile = true"
          ></previewfile>
          <!-- <outboxrightclick
            :show="showoutboxMenu"             
            :AreaX="x"
            :AreaY="y"
            :file="currentfile"
          ></outboxrightclick> -->
          <dialogmanageheader
            :show="opendialogmanageheader"
            @closedialog="opendialogmanageheader = false"
            @reload="getSourceSystem(), getfeature()"
          ></dialogmanageheader>
          <dialogalersearchdonotfillout
            :show="opendialogalersearchdonotfillout"
            @closedialog="opendialogalersearchdonotfillout = false"
          ></dialogalersearchdonotfillout>
          <dialogspecifyconditions
            :show="opendialogspecifyconditions"
            @closedialog="(opendialogspecifyconditions) = false"
          ></dialogspecifyconditions>
          <dialogcheckdeletefilter
            :filedata= "list_documents"
            :totalfile= "total_delete_file"
            :type= "type_outbox"
            :show="opendialogcheckdeletefilter"
            @closedialog="opendialogcheckdeletefilter = false"
            @reload="getSourceSystem()"
          ></dialogcheckdeletefilter>
          <dialogalertcheckdeletefilter
            :show="opendialogalertcheckdeletefilter"
            :filedata="totalfile"
            @closedialog="opendialogalertcheckdeletefilter = false"
          ></dialogalertcheckdeletefilter>
          <dialogmanagerenamedocumentinbox
            :show="opendialogmanagerenamedocumentinbox"
            :statusinboxoutbox="'outbox'"
            @closedialog="opendialogmanagerenamedocumentinbox = false"
          ></dialogmanagerenamedocumentinbox>
        </div>
      </v-card>

      <!-- snackbardowload show dowloadfilemulti error -->
      <div class="text-center">
        <v-snackbar
          :style="`padding-top: ${i * 90}px`"
          v-for="(s, i) in snackbars"
          :key="i"
          v-model="snackbar_download_error"
          :timeout="20000"
          top
          right
        >
          <v-list style="padding-top: 0px;">
            <v-list-item dense style="height:40px; width:450px;" three-line>
              <v-list-item-icon>
                <v-icon class="mt-6" large color="red"
                  >mdi-alert-octagon-outline</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ s.filename }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  "errorMessage : " + s.error
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn class="mt-2" color="red" text @click="hide(i)" fab small>
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>

      <!-- snackbardowload -->
      <div class="text-center ma-2">
        <v-snackbar v-model="snackbardowload" bottom color="white">
          <v-list color="white" style="padding-top: 0px;">
            <v-toolbar
              :color="color.theme"
              dense
              short
              :dark="color.darkTheme"
              flat
              fab
            >
              {{ $t("myinboxPage.dowload.dowloading") }}
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="snackbardowload = false"
                fab
                x-small
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content>
                <v-list-item-title
                  >{{ OutboxFile }} {{ datetime }}</v-list-item-title
                >
              </v-list-item-content>
              <!-- <v-progress-circular
          v-if="percentCompleted <= 95"
          :rotate="90"
          :size="35"
          :width="3"
          :value= percentCompleted
          color="grey darken-1"
          style="margin-left:25px"
        >&nbsp; {{ percentCompleted }}
              </v-progress-circular>-->
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="percentCompleted"
                color="teal"
                style="margin-left:25px"
                >&nbsp; {{ percentCompleted }}</v-progress-circular
              >
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>

      <!-- snackbardowloadfile 1 file -->
      <div class="text-center ma-2">
        <v-snackbar v-model="snackbardowloadonefile" bottom color="white">
          <v-list color="white" style="padding-top: 0px;">
            <v-toolbar
              :color="color.theme"
              dense
              short
              :dark="color.darkTheme"
              flat
              fab
            >
              {{ $t("myinboxPage.dowload.dowloading") }}
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="snackbardowloadonefile = false"
                fab
                x-small
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content>
                <v-list-item-title>{{ namedowload }}</v-list-item-title>
              </v-list-item-content>
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="percentCompleted"
                color="teal"
                style="margin-left:25px"
                >&nbsp; {{ percentCompleted }}</v-progress-circular
              >
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>
      <!-- snackbardowloadfileทั้งหมด -->
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbardowloadonetotal"
          :timeout="timeout"
          bottom
          color="white"
        >
          <v-list color="white" style="padding-top: 0px;">
            <v-toolbar
              :color="color.theme"
              dense
              short
              :dark="color.darkTheme"
              flat
              fab
            >
              {{ $t("myinboxPage.dowload.dowloading") }}
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="snackbardowloadonetotal = false"
                fab
                x-small
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content>
                <!-- <v-list-item-title style="color:black">{{ExportOutboxFiles}}</v-list-item-title>     -->
                <v-list-item-title style="color:black">{{
                  this.datenow + "_OutboxFiles"
                }}</v-list-item-title>
                <v-list-item-subtitle class="pa-1">
                  <v-progress-linear
                    :value="percentCompleted"
                    color="teal"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-icon v-if="percentCompleted === 100" color="green"
                >mdi-check-bold</v-icon
              >
              <!-- <v-list-item-content>
                <v-list-item-title>{{ExportOutboxFiles}}</v-list-item-title>
              </v-list-item-content>
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="percentCompleted"
                color="teal"
                style="margin-left:25px"
              >&nbsp; {{ percentCompleted }}</v-progress-circular> -->
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>

      <!-- snackbardowloadfilecsv ทั้งหมด -->
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbardowloadonetotalcsv"
          :timeout="timeout"
          bottom
          color="white"
        >
          <v-list color="white" style="padding-top: 0px;">
            <v-toolbar
              :color="color.theme"
              dense
              short
              :dark="color.darkTheme"
              flat
              fab
            >
              {{ $t("myinboxPage.dowload.dowloading") }}
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="snackbardowloadonetotalcsv = false"
                fab
                x-small
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content>
                <v-list-item-title style="color:black">{{
                  ExportOutboxFilescsv
                }}</v-list-item-title>
                <v-list-item-subtitle class="pa-1">
                  <v-progress-linear
                    :value="percentCompleted"
                    color="teal"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-icon v-if="percentCompleted === 100" color="green"
                >mdi-check-bold</v-icon
              >
              <!-- <v-list-item-content>
                <v-list-item-title>{{ExportOutboxFilescsv}}</v-list-item-title>
              </v-list-item-content>
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="percentCompleted"
                color="teal"
                style="margin-left:25px"
              >&nbsp; {{ percentCompleted }}</v-progress-circular> -->
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>
      <!-- snackbardowloadfileexcel ทั้งหมด -->
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbardowloadonetotalexcel"
          :timeout="timeout"
          bottom
          color="white"
        >
          <v-list color="white" style="padding-top: 0px;">
            <v-toolbar
              :color="color.theme"
              dense
              short
              :dark="color.darkTheme"
              flat
              fab
            >
              {{ $t("myinboxPage.dowload.dowloading") }}
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="snackbardowloadonetotalexcel = false"
                fab
                x-small
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content>
                <v-list-item-title style="color:black">{{
                  ExportOutboxFilesexcel
                }}</v-list-item-title>
                <v-list-item-subtitle class="pa-1">
                  <v-progress-linear
                    :value="percentCompleted"
                    color="teal"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-icon v-if="percentCompleted === 100" color="green"
                >mdi-check-bold</v-icon
              >
              <!-- <v-list-item-content>
                <v-list-item-title>{{ExportOutboxFilesexcel}}</v-list-item-title>
              </v-list-item-content>
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="percentCompleted"
                color="teal"
                style="margin-left:25px"
              >&nbsp; {{ percentCompleted }}</v-progress-circular> -->
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import VueCookies from "vue-cookies";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
import format from "date-fns/format";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const previewfile = () => import("../components/optional/dialog-preview");
// const outboxapproved = () =>
//   import("../components/optional/dialog-outboxapproved");
const dialogdetailoutbox = () =>
  import("../components/optional/dialog-detailoutbox");
const outboxdetail = () => import("../components/optional/dialog-outboxdetail");
const insertrelation = () =>
  import("../components/optional/dialog-insertrelationoutbox");
const dialogcheckdelete = () =>
  import("../components/optional/dialog-checkdeleteoutbox");
const dialogcheckrequestdelete = () =>
  import("../components/optional/dialog-checkrequestdeleteoutbox");
const dialogsnackbermultidowload = () =>
  import("../components/optional/dialog-snackbarmultidowmload");
const dialogedit = () =>
  import("../components/optional/dialog-editexpire-in-out");
const dialogmultideleteinboxoutbox = () =>
  import("../components/optional/dialog-multideletetotrashinboxoutbox");
const dialogcheckdeleterightclick = () =>
  import("../components/optional/dialog-checkdeleterightclick");
const dialogconfirmdelete = () =>
  import("../components/optional/dialog-confirmdelete");
const dialogresendmail = () =>
  import("../components/optional/dialog-resendmail");
const dialogselectheader = () =>
  import("../components/optional/dialog-selectheader.vue");
const dialogsnackbarloading_print = () =>
  import("../components/optional/dialog-snackbarloadding-print.vue");
const outboxrightclick = () =>
  import("../components/contextmenu/outboxrightclick.vue");
const dialogmanageheader = () =>
  import("../components/optional/dialog-manageheaderoutbox.vue");
const dialogalersearchdonotfillout = () =>
  import("../components/optional/dialog-search-donotfillout.vue");
const dialogspecifyconditions = () =>
  import("../components/optional/dialog-newalert-inbox-outbox.vue");
const dialogcheckdeletefilter = () =>
  import("../components/optional/dialog-checkdelete-filter.vue");
const dialogalertcheckdeletefilter = () =>
  import("../components/optional/dialog-alert-checkdelete-filter.vue")
const dialogmanagerenamedocumentinbox = () =>
  import("../components/optional/dialog-managerenamedocument.vue")

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  name: "myoutbox_sourcesystem",
  // ลบ outboxapproved ออกก่อน
  components: {
    dialogedit,
    onebox_toolbar,
    previewfile,
    dialogdetailoutbox,
    outboxdetail,
    insertrelation,
    dialogcheckdelete,
    dialogcheckrequestdelete,
    dialogsnackbermultidowload,
    dialogmultideleteinboxoutbox,
    dialogcheckdeleterightclick,
    dialogconfirmdelete,
    dialogresendmail,
    dialogselectheader,
    dialogsnackbarloading_print,
    outboxrightclick,
    dialogmanageheader,
    dialogalersearchdonotfillout,
    dialogspecifyconditions,
    dialogcheckdeletefilter,
    dialogalertcheckdeletefilter,
    dialogmanagerenamedocumentinbox,
  },
  data: function() {
    return {
      opendialogmanagerenamedocumentinbox: false,
      check_download_attachments: false,
      check_import_search: false,
      total_file_import:0,
      data_file_import:[],
      import_file_document_no: null,
      show_info_import: false,
      arrayfileidselect: [],
      showtotalfileonly: false,
      type_outbox:"outbox",
      total_delete_file:0,
      list_documents:[],
      opendialogcheckdeletefilter:false,
      opendialogalertcheckdeletefilter: false,
      opendialogspecifyconditions: false,
      show_info_doc:false,
      show_info:false,
      opendialogalersearchdonotfillout: false,
      show_back: false,
      x: 0,
      y: 0,
      opendialogmanageheader: false,
      showoutboxMenu: false,
      statusloading: "",
      count_loadingfile: 0,
      count_allfile: 0,
      closeOnContentClick: false,
      typedownloads: false,
      typeitem: false,
      showToolTipExportFile: false,
      showToolTipExportFilecsv: false,
      showToolTipExportFileExcel: false,
      showToolTipFileDetail: false,
      showToolTipDownloadFile: false,
      showToolTipDeleteForever: false,
      opensnackbarloadingprint: false,
      loaddataprogress_print: false,
      show_menu: false,
      show_download: false,
      show_delete: false,
      show_print: false,
      show_clear: false,
      show_download_all: false,
      show_download_all_csv: false,
      show_download_all_excel: false,
      sorting: {
        sort: [
          {
            id: "document_date",
            text: "sorting.document_date",
          },
          {
            id: "document_type",
            text: "sorting.document_type",
          },
          {
            id: "cre_dtm",
            text: "sorting.cre_dtm",
          },
          {
            id: "customer_name",
            text: "sorting.customer_name",
          },
          {
            id: "saler_name",
            text: "sorting.saler_name",
          },
          {
            id: "filename",
            text: "sorting.filename",
          },
          {
            id: "document_no",
            text: "sorting.doc_no",
          },
          {
            id: "status",
            text: "sorting.status",
          },
        ],
        direction: [
          {
            id: "ASC",
            text: "sorting.ascending",
          },
          {
            id: "DESC",
            text: "sorting.descending",
          },
        ],
      },
      sort_by: "filename",
      direction: "ASC",
      datenow: format(new Date(), "dd_MM_yyyy"),
      datenow_eng: format(new Date(), "yyyy-MM-dd"),
      timeout: -1,
      filename_error: "",
      errorMessage: "",
      snackbar_download_error: false,
      snackbars: [],
      typedownload: "",
      listheader: [],
      opendialogselectheader: false,
      openresendmail: false,
      maxDisplayfileextension: 1,
      maxDisplay: 1,
      confirmdelete: false,
      opensdialogcheckdeleterightclick: false,
      listdata: "",
      btsdownload: false,
      opensdialogmultidelinboxoutbox: false,
      status_data: "",
      opendialogedit: false,
      opensnackbarmultidownload: false,
      percent: 0,
      data: "",
      statusdelete: "",
      statusrequestdelete: "",
      statusbox: "",
      boxtype: [
        {
          box_type: "มี",
          box_value: "Y",
        },
        {
          box_type: "ไม่มี",
          box_value: "N",
        },
      ],
      checkpagination: false,
      checkdataoutbox: [],
      openreletion: false,
      sortStatus: "",
      statusde: "All",
      // liststatus:["All","Active","Pending","Reject","Request","Unsend"],
      liststatus: [
        "All",
        "Active",
        "Pending",
        "Duplicate",
        "Resend From Etax",
        "Sender Delete",
        "Request For Removal Sent",
        "Reject"
      ],
      newdata: [],
      oneboxtype: [],
      openoutbox: false,
      opendialog: false,
      opendialogdelete: false,
      opendialogrequestdelete: false,
      ExportOutboxFiles: "ExportOutboxFiles",
      ExportOutboxFilescsv: "ExportOutboxFilescsv",
      ExportOutboxFilesexcel: "ExportOutboxFilesexcel",
      snackbardowloadonetotal: false,
      snackbardowloadonetotalcsv: false,
      snackbardowloadonetotalexcel: false,
      OutboxFile: "OutboxFile",
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      snackbardowloadonefile: false,
      search: "",
      filemultipledownload: [],
      statusmutipledownload: false,
      defaulticon: "",
      listExtensionFile: [],
      listDoctype: [],
      currentfile: {},
      openoutboxapproved: false,
      openpreviewfile: false,
      nodata: this.$t("myoutboxPage.Nodata"),
      panel: 0,
      filename: "",
      fileextension: [],
      documentid: "",
      documentidto: "",
      documenttype: [],
      sendername: "",
      systemname: "",
      sellertaxid: "",
      sellerbranchid: "",
      ponumberinput: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      loaddataprogress: false,
      outboxdata: [],
      fakeData: [],
      statusonechat: [],
      liststatusonechat: [
        {
          status_text: "ไม่ส่ง",
          status_code: "0",
        },
        {
          status_text: "รอส่ง",
          status_code: "1",
        },
        {
          status_text: "ส่งแล้ว",
          status_code: "2",
        },
        {
          status_text: "อ่านแล้ว",
          status_code: "3",
        },
      ],
      // headers: [
      //   {
      //     text: "#",
      //     align: "center",
      //     sortable: false,
      //     value: "data_type",
      //     width: "5%"
      //   },
      //   {
      //     text: "tablefile.filenameonly",
      //     align: "left",
      //     value: "filename",
      //     sortable: false,
      //     width: "20%"
      //   },
      //   {
      //     text: "tablefile.docno",
      //     align: "left",
      //     value: "document_no",
      //     sortable: false,
      //     width: "10%"
      //   },
      //   {
      //     text: "tablefile.docdate",
      //     align: "center",
      //     value: "document_date",
      //     sortable: false,
      //     width: "10%"
      //   },
      //   {
      //     text: "tablefile.doctype",
      //     value: "document_type",
      //     align: "center",
      //     sortable: false,
      //     width: "10%"
      //   },

      //   {
      //     text: "tablefile.receivername",
      //     value: "receiver_name",
      //     align: "center",
      //     sortable: false,
      //     width: "10%"
      //   },
      //   // {
      //   //   text: "tablefile.sendername",
      //   //   value: "sender_name",
      //   //   align: "left",
      //   //   sortable: false,
      //   //   width: "15%"
      //   // },
      //   {
      //     text: "tablefile.outboxdate",
      //     value: "cre_dtm",
      //     align: "center",
      //     sortable: false,
      //     width: "10%"
      //   },
      //   {
      //     text: "tablefile.status_onechat",
      //     value: "status_onechat",
      //     align: "center",
      //     sortable: false,
      //     width: "10%"
      //   },
      //   // {
      //   //   text: "tablefile.resendmail",
      //   //   value: "resendmail",
      //   //   align: "left",
      //   //   sortable: false,
      //   //   width: "10%"
      //   // },
      //   {
      //     text: "tablefile.option",
      //     value: "file_id",
      //     align: "left",
      //     sortable: false,
      //     width: "5%"
      //   }
      // ],
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      size: 10,
      page: 1,
      totalfile: 0,
      sortby: "",
      clickSort: false,
      clickSortorderby: false,
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      multiSort: false,
      namedowload: "",
      feature: {},
      checkstatusalloutbox: false,
      // package: "",
    };
  },
  computed: {
    ...mapState([
      "status_permission_outbox",
      "status_permission_inbox",
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "storage_usage_biz",
      "role_level",
      "feature_renamedocument",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      console.log("screen", this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totalfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata: {
      get() {
        let companyname = "";
        if (this.$route.query.type === 2 || this.$route.query.type === "2") {
          this.defaulticon = "business";
          if (this.$t("default") === "th") {
            if (
              this.$route.query.nameth === undefined ||
              this.$route.query.nameth === null ||
              this.$route.query.nameth === ""
            ) {
              companyname =
                this.dataAccountActive["business_info"]["first_name_th"] || "-";
            } else {
              companyname = this.$route.query.nameth;
            }
          } else {
            if (
              this.$route.query.nameen === undefined ||
              this.$route.query.nameen === null ||
              this.$route.query.nameen === ""
            ) {
              companyname =
                this.dataAccountActive["business_info"]["first_name_eng"] ||
                "-";
            } else {
              companyname = this.$route.query.nameen;
            }
          }

          return this.$t("toolbar.myoutbox.Businessname") + " " + companyname;
          //  this.getSourceSystem()
        } else {
          this.defaulticon = "face";
          //  this.getSourceSystem()
          return this.$t("toolbar.myoutbox.Personalname");
        }
      },
    },
    showdatafilter() {
      // console.log(search);
      return this.outboxdata.filter((item) => {
        return (
          item.filename.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    icon() {
      if (this.Alldoctype) return "mdi-close-box";
      if (this.Somedoctype) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icons() {
      if (this.Allfileextension) return "mdi-close-box";
      if (this.Somefileextension) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon_() {
      if (this.Alloneboxtype) return "mdi-close-box";
      if (this.Someoneboxtype) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon_all() {
      if (this.Allstatussendtoonechat) return "mdi-close-box";
      if (this.Somestatussendtoonechat) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    Alldoctype() {
      return this.documenttype.length === this.listDoctype.length;
    },
    Somedoctype() {
      return this.documenttype.length > 0 && !this.Alldoctype;
    },
    Allfileextension() {
      return this.fileextension.length === this.listExtensionFile.length;
    },
    Somefileextension() {
      return this.fileextension.length > 0 && !this.Allfileextension;
    },
    Alloneboxtype() {
      return this.oneboxtype.length === this.boxtype.length;
    },
    Someoneboxtype() {
      return this.oneboxtype.length > 0 && !this.Alloneboxtype;
    },
    Allstatussendtoonechat() {
      return this.statusonechat.length === this.liststatusonechat.length;
    },
    Somestatussendtoonechat() {
      return this.statusonechat.length > 0 && !this.Allstatussendtoonechat;
    },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false;
      // console.log('this.package',this.package);
      // return !(this.package === 'Y')
    },
    disableDataStorageOverload() {
      return (
        this.convertstorageToByte(this.storage_usage_biz) >
        this.convertstorageToByte(this.dataStorageMax)
      );
    },
    listsize() {
      return [
        { value: 10, disable: false },
        { value: 20, disable: this.disableByPackage },
        { value: 50, disable: this.disableByPackage },
        { value: 100, disable: this.disableByPackage },
        { value: 1000, disable: this.disableByPackage },
      ];
    },
    headers() {
      if (this.dataAccountActive["type"] === "Business") {
        let _items = [
          {
            text: "#",
            align: "center",
            sortable: false,
            value: "data_type",
            width: "5%",
          },
        ];
        if (this.feature.filename === "Y") {
          _items.push({
            text: "tablefile.filenameonly",
            align: "left",
            value: "filename",
            sortable: false,
            width: "20%",
          });
        }
        if (this.feature.document_no === "Y") {
          _items.push({
            text: "tablefile.docno",
            align: "left",
            value: "document_no",
            sortable: false,
            width: "10%",
          });
        }
        if (this.feature.PO === "Y") {
          _items.push({
            text: "tablefile.po_number",
            align: "center",
            value: "PO",
            sortable: false,
            width: "10%",
          });
        }
        if (this.feature.document_date === "Y") {
          _items.push({
            text: "tablefile.docdate",
            align: "center",
            value: "document_date",
            sortable: false,
            width: "10%",
          });
        }
        if (this.feature.document_type_code === "Y") {
          _items.push({
            text: "tablefile.doctype",
            value: "document_type",
            align: "center",
            sortable: false,
            width: "10%",
          });
        }
        if (this.feature.buyer_name === "Y") {
          _items.push({
            text: "tablefile.receivername",
            value: "receiver_name",
            align: "center",
            sortable: false,
            width: "10%",
          });
        }
        if (this.feature.cre_dtm === "Y") {
          _items.push({
            text: "tablefile.outboxdate",
            value: "cre_dtm",
            align: "center",
            sortable: false,
            width: "10%",
          });
        }
        if (this.feature.status_onechat === "Y") {
          _items.push({
            text: "tablefile.status_onechat",
            value: "status_onechat",
            align: "center",
            sortable: false,
            width: "10%",
          });
        }
        _items.push({
          text: "tablefile.option",
          value: "file_id",
          align: "center",
          sortable: false,
          width: "5%",
        });
        return _items;
      } else {
        let _items = [
          {
            text: "#",
            align: "center",
            sortable: false,
            value: "data_type",
            width: "5%",
          },
          {
            text: "tablefile.filenameonly",
            align: "left",
            value: "filename",
            sortable: false,
            width: "20%",
          },
          {
            text: "tablefile.docno",
            align: "left",
            value: "document_no",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.po_number",
            align: "center",
            value: "PO",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.docdate",
            align: "center",
            value: "document_date",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.doctype",
            value: "document_type",
            align: "center",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.receivername",
            value: "receiver_name",
            align: "center",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.outboxdate",
            value: "cre_dtm",
            align: "center",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.status_onechat",
            value: "status_onechat",
            align: "center",
            sortable: false,
            width: "10%",
          },
          {
            text: "tablefile.option",
            value: "file_id",
            align: "center",
            sortable: false,
            width: "5%",
          },
        ];
        return _items;
      }
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  watch: {
    sort_by() {
      this.getSourceSystem();
      this.getDataFilters();
    },
    direction() {
      this.getSourceSystem();
      this.getDataFilters();
    },
  },
  methods: {
    async import_file_for_search(){
      // ช่องแนบไฟล์ต้องไม่ว่าง
      if(this.import_file_document_no === null || this.import_file_document_no === undefined){
        Toast.fire({
          icon: "warning",
          title: this.$t("dcmPage.please_attach_file"),
        });
      }else{
        // ถ้าไฟล์แนบไม่ใช่นามสกุล xlsx
        var file_name_string = this.import_file_document_no.name
        var file_name_array = file_name_string.split(".");
        var file_extension = file_name_array[file_name_array.length - 1];
        // console.log("file_extension",file_extension);
      if(file_extension !== 'xlsx'){
        Toast.fire({
            icon: "warning",
            title: this.$t("dcmPage.error_searchfileattachments_2"),
          });
      }else{
      this.check_import_search = true;
      this.loaddataprogress = true;
      this.totalfile = 0;
      this.checkdataoutbox = [];
      this.outboxdata = [];
      let offset = this.checkpagination === true ? this.size * (this.page - 1) : 0 
      // console.log("system",this.$route.query.systemid);
      const formData = new FormData();
        for(let i = 0; i<this.$route.query.systemid.length; i++){
          formData.append("system", this.$route.query.systemid[i])
        }
        formData.append("sender_id", this.dataAccountActive.business_info["business_id"]);
        formData.append("sender_type", "2");
        formData.append("file", this.import_file_document_no);
        formData.append("limit", this.size.toString());
        formData.append("offset", offset.toString());
        formData.append("status", this.sortStatus)
      
      console.log("formData",formData);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      await this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/get_file_outbox/import", formData, {
          headers: { Authorization: auth.code },}
        )
        .then((res) => {
          // console.log("response",res);
          // this.outboxdata = [];
          if (res.data.status === "OK") {
            console.log("response1111", res);

            for (let i = 0; i < res.data.result.data.length; i++) {
              this.checkdataoutbox.push(res.data.result.data[i]);
              // this.checkdataoutbox.push(file_outbox);
              console.log(this.checkdataoutbox);
            }

            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              // console.log("this.checkdataoutbox",this.checkdataoutbox);
              this.outboxdata = this.checkdataoutbox;

              // this.outboxdata = res.data.result["data"];
              console.log("outbox----", this.outboxdata);
              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.outboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
            this.panel = false;
          } else {
            if(res.data.errorMessage === "APIV1Get_file_outbox_import ==> 'document_no' (line : outbox.py 3316)"){
            Toast.fire({
              icon: "error",
              title: this.$t("dcmPage.error_searchfileattachments_1"),
            });
            this.loaddataprogress = false;
            // }else if(res.data.errorMessage === "APIV1Get_file_outbox_import ==> Excel file format cannot be determined, you must specify an engine manually. (line : outbox.py 3305)"){
            //   Toast.fire({
            //   icon: "error",
            //   title: this.$t("dcmPage.error_searchfileattachments_2"),
            // });
            // this.loaddataprogress = false;
            // }else if(res.data.errorMessage === "APIV1Get_file_outbox_import ==> can only concatenate str (not \"numpy.float64\") to str (line : inbox.py 5774)"){
            //   Toast.fire({
            //   icon: "error",
            //   title: this.$t("dcmPage.error_searchfileattachments_3"),
            // });
            // this.loaddataprogress = false;
            }else{
            Toast.fire({
              icon: "error",
              title: res.data.errorCode +" "+ res.data.errorMessage,
            });
            this.loaddataprogress = false;
            this.totalfile = 0;
            }
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
      }
      }
    },
    download_example_file(){
      console.log("download_example_file");
      let url = process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/create_template/export"
        this.axios
            .get(url, {
            withCredentials: false,
            responseType: "blob",
          })

        .then((response) => {
          console.log("response",response);
          if (response.status == 200) {
                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                // กำหนดชื่อไฟล์
                link.download = "input_document_no";
                  // format(new Date(), "dd_MM_yyyy") + "_Inbox_Excel";
                link.click();

                Toast.fire({
              icon: "success",
              title: this.$t("ดาวน์โหลดไฟล์ตัวอย่างสำเร็จ"),
            });
          }
          else {
            Toast.fire({
              icon: "error",
              title: this.$t("ดาวน์โหลดไฟล์ตัวอย่างไม่สำเร็จ"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    clearDocumentId () {
      this.documentidto = ""
    },
    setdate_(date_) {
      if (date_) {
      console.log('date_',date_);
      var date = new Date(date_)
      date.setDate(date.getDate() - 90)
      console.log(date);
      return new Date(date).toISOString().substr(0, 10);
      }
      return date_
    },
    setdate(date_) {
      if (date_) {
        console.log('date_',date_);
        var date = new Date(date_);
        var dateNow = new Date();
        date.setDate(date.getDate() + 90)
        console.log(date);
        // return new Date(date).toISOString().substr(0, 10);
        if (date > dateNow) {
          return new Date(date).toISOString().substr(0, 10);
          // return new Date(dateNow).toISOString().substr(0, 10);
        } else {
          console.log(date);
          return new Date(date).toISOString().substr(0, 10);
        }
      }
      return date_
    },
    backtopreventfolder() {
      if (this.checkstatusalloutbox !== true) {
        this.$router.push({
          path: "/myoutbox-system",
          query: { type: "2" },
        });
      } else {
        this.$router.push({
        path: "/allbusinessoutbox-system",
          query: {
            type: 2,
            po_number: this.ponumberinput === null ? "" : this.ponumberinput,
            sourcesystem: this.$route.query.sourcesystem,
            systemid: this.$route.query.systemid,
            filename: this.filename,
            fileextension: this.fileextension,
            fromdate: this.fromdateupload,
            todate: this.todateupload,
            fromdatedoc: this.fromdatedocument,
            todatedoc: this.todatedocument,
            doc_no_start: this.documentid,
            doc_no_end: this.documentidto,
            sender_name: this.sendername,
            sellerTaxid: this.sellertaxid,
            sellerBranchid: this.sellerbranchid,
            receiver_name: this.receivername,
            receiverTaxid: this.buyertaxid,
            receiverBranchid: this.buyerbranchid,
          },
        });
      }
    },
    outboxrightclick(e, filename) {
      e.preventDefault();
      console.log(e);
      e.preventDefault();
      this.showoutboxMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        console.log(filename);
        this.currentfile = filename;
        this.showoutboxMenu = true;
      });
    },
    async fn_printPDF() {
      await this.checkTrashStatus();
      if (this.filemultipledownload.length <= 0) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        this.opensnackbarloadingprint = true;
        // this.loaddataprogress_print = true;
        this.percent = 0;
        let listfile = "";
        let notpdf = false;
        let i;
        console.log("this.filemultipledownload", this.filemultipledownload);
        for (i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i]["data_type"] !== "pdf") {
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.pleasechoosepdf"),
            });
            notpdf = true;
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
            // } else if (this.filemultipledownload[i]["status_lock_data"] === "Y") {
            //   Toast.fire({
            //     icon: "error",
            //     title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
            //   });
            //   notpdf = true;
            //   this.loaddataprogress_print = false;
          } else {
            listfile += "&list_file=" + this.filemultipledownload[i]["file_id"];
            //console.log(listfile);
          }
        }
        if (i === this.filemultipledownload.length && notpdf !== true) {
          let auth = await gbfGenerate.generateToken();

          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/merge_pdf?account_id=" +
            this.dataAccountId +
            "&user_id=" +
            this.dataUsername +
            "&Authorization=" +
            auth.code +
            listfile;
          this.axios
            .get(url, {
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                console.log("progressEvent", progressEvent);
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                // this.loadingpercent = progresspercent;
                this.percent = progresspercent;
                // console.log("progresspercent",progresspercent);
              },
              responseType: 'blob',
            })
            .then((response) => {
              console.log(response.data.status);
              if (response.data.type === "application/pdf") {
                this.statusmutipledownload = false;
                this.filemultipledownload = [];
                this.opensnackbarloadingprint = false;
                // this.loaddataprogress_print = false;
                let url = window.URL.createObjectURL(response.data);
                window.open(url).print();
              } else {
                this.opensnackbarloadingprint = false;
                // this.loaddataprogress_print = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถรวมไฟล์เสียได้",
                });
              }
            });
        }
      }
    },
    calcMargin(i) {
      return i * 60 + "px";
    },
    hide(i) {
      this.snackbars.splice(i, 1);
    },
    fn_selectallstatusonechat() {
      let liststatus = [];
      this.$nextTick(() => {
        if (this.Allstatussendtoonechat) {
          this.statusonechat = [];
        } else {
          for (let i = 0; i < this.liststatusonechat.slice().length; i++) {
            liststatus.push(this.liststatusonechat[i].status_code);
          }
          this.statusonechat = liststatus;
        }
      });
    },
    fn_openresendmail(file) {
      this.listdata = file;
      this.openresendmail = true;
      console.log(this.openresendmail);
    },
    fn_selectall_boxtype() {
      this.$nextTick(() => {
        if (this.Alloneboxtype) {
          this.oneboxtype = [];
          console.log("oneboxtype", this.oneboxtype);
        } else {
          this.oneboxtype = this.boxtype.slice();
          console.log("this.oneboxtype", this.oneboxtype);
        }
      });
    },
    fn_selectall_fileextension() {
      let listex = [];
      this.$nextTick(() => {
        if (this.Allfileextension) {
          this.fileextension = [];
          console.log("fileextension", this.fileextension);
        } else {
          console.log(this.fileextension);
          console.log(this.listExtensionFile);
          for (let i = 0; i < this.listExtensionFile.slice().length; i++) {
            listex.push(this.listExtensionFile[i].data_type);
          }
          this.fileextension = listex;
          // // this.fileextension = this.listExtensionFile.slice()
          // console.log("fileextension else",this.fileextension);
        }
      });
    },
    fn_selectall() {
      let listdocument = [];
      this.$nextTick(() => {
        console.log(this.Alldoctype);
        if (this.Alldoctype) {
          this.documenttype = [];
          // console.log("documenttype",this.documenttype);
        } else {
          for (let i = 0; i < this.listDoctype.slice().length; i++) {
            listdocument.push(this.listDoctype[i].document_type);
          }
          this.documenttype = listdocument;
          // console.log("documenttype else",this.documenttype);
        }
      });
    },
    async fn_canceldeleterequest(file) {
      // console.log(file);
      let payload = {
        account_id: this.dataAccountId,
        inbox_id: [file.inbox_id],
        request_answer: "N",
        delete_log: "outbox",
        user_id: this.dataUsername,
      };
      let auth = await gbfGenerate.generateToken();
      // console.log("payload",payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/delete_log_inbox_requset",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Cancel Delete Request Success",
            });
            this.getSourceSystem();
          } else {
            Toast.fire({
              icon: "erroe",
              title: response.errorMessage,
            });
          }
        });
    },
    // เปิด Dialog
    fn_openset() {
      this.opendialogedit = true;
    },
    async deleterequest(_item, status) {
      // console.log(_item,status);
      let payload = {
        account_id: this.dataAccountId,
        inbox_id: [_item.inbox_id],
        request_answer: status,
        delete_log: "outbox",
        user_id: this.dataUsername,
      };
      let auth = await gbfGenerate.generateToken();
      // console.log("payload",payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/delete_log_inbox_requset",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if (status === "Y") {
              Toast.fire({
                icon: "success",
                title: "Delete Success",
              });
              this.getSourceSystem();
            } else if (status === "N") {
              Toast.fire({
                icon: "success",
                title: "Success",
              });
              this.getSourceSystem();
            }
          }
        });
    },
    // checkstatusbox(item){
    //   // console.log("item status box",item);
    //      this.statusbox= ''
    //      if(item === ''){
    //        this.statusbox = ''
    //      }else if(item === "มี"){
    //       this.statusbox = 'Y'
    //      }else if(item === "ไม่มี"){
    //       this.statusbox = 'N'
    //      }
    //     //  console.log('rrrrr',this.statusbox);
    // },
    async buntonRequest(item) {
      let payload = {
        inbox_id: [item.inbox_id],
        status: "request",
        user_id: "",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      // console.log("payload",payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_unsend_inbox",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getSourceSystem();
          }
        });
    },
    // settablecolor(parameter){
    alertcondition(){
      console.log("ffffffff");
      if(VueCookies.get("checkalertcondition") === "true"){
        this.opendialogspecifyconditions = true
      } 
    },
    // closeclicksort() {
    //   this.clickSortorderby = false;
    //   this.checkpagination = false;
    //   this.check();
    // },
    // check() {
    //   if (this.checkpagination === true) {
    //     this.checksortorderby();
    //   } else {
    //     this.getSourceSystem();
    //   }
    // },
    checksortorderby() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.$router.push("/myoutbox-system");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.sortorderby(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.getDataExtensionFile(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.getDataDocType(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.sortorderby(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.sortorderby(this.$route.query.biz, "2");
          }
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.sortorderby(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
      }
    },
    async sortorderby(_accountid, _type) {
      this.loaddataprogress = true;
      this.checkdataoutbox = [];
      this.outboxdata = [];
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          // console.log("response",res);
          // this.outboxdata = [];
          if (res.data.status === "OK") {
            console.log("orderbyyyyyyy", res);

            for (let i = 0; i < res.data.result.data.length; i++) {
              // this.package = res.data.package;
              //   // if(res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2 ){
              //     this.checkdataoutbox.push(res.data.result.data[i])
              //   // }
              this.checkdataoutbox.push(res.data.result.data[i]);
            }

            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              // console.log("this.checkdataoutbox",this.checkdataoutbox);
              this.outboxdata = this.checkdataoutbox;

              // this.outboxdata = res.data.result["data"];
              console.log("outbox----", this.outboxdata);
              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.outboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    clickstatus(val) {
      console.log("val", val);
      this.page = 1;
      let sortStatus = "";
      if (val === "All") {
        this.sortStatus = "";
      } else if (val === "Active") {
        this.sortStatus = "active";
      } else if (val === "Pending") {
        this.sortStatus = "pending";
      } else if (val === "Reject") {
        // this.sortStatus = "reject";
        this.sortStatus = 'receiver_denied_request'
      } else if (val === "Request") {
        this.sortStatus = "request";
      } else if (val === "Unsend") {
        this.sortStatus = "unsend";
      } else if (val === "Duplicate") {
        this.sortStatus = "duplicate";
      } else if (val === "Request For Removal Sent") {
        this.sortStatus = "request_for_removal_sent";
      } 
      // else if (val === "receiver denied request") {
      //   this.sortStatus = "receiver_denied_request";
      // } 
      else if (val === "Resend From Etax") {
        this.sortStatus = "resend";
      } else if (val === "Sender Delete") {
        this.sortStatus = "sender_delete";
      }

      console.log("sortStatus", this.sortStatus);
      if(this.check_import_search === true){
        this.import_file_for_search();
      }else{
      this.searchdocument();
      }
      // this.getSourceSystem();
    },

    // fakeOutboxApproveData() {
    //   let fakeData = this.outboxdata;
    //   for (let i = 0; i < fakeData.length; i++) {
    //     if (i % 3 == 0) {
    //       fakeData[i]["status"] = "waiting";
    //     } else if (i % 3 == 1) {
    //       fakeData[i]["status"] = "approve";
    //     } else {
    //       fakeData[i]["status"] = "reject";
    //     }
    //   }
    //   this.fakeData =fakeData
    //   console.log('Fake Data Outbox Approve',this.fakeData)
    // },
    viewLog(mes, value) {
      console.log(mes);
      console.log("Value:", JSON.stringify(value));
    },

    fn_confirmdelete(file) {
      this.listdata = file;
      console.log("++", this.listdata);
      this.confirmdelete = true;
    },
    //ลบลงถังขยะ ใหม่
    async fn_deletetotrash(file) {
      //  console.log(file);
      let api;
      let payload;
      let filedata = [];
      filedata.push(file["inbox_id"]);
      payload = {
        outbox_id: filedata,
        account_id: this.dataAccountId,
      };
      api = "/api/delete_outbox";
      console.log("Payload =>", JSON.stringify(payload));
      let auth = await gbfGenerate.generateToken();
      console.log(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api);
      //API
      //เหลือยิงเทสรอผล
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("res");
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.clearmultipledownload();
            this.statusmutipledownload = false;
            this.getSourceSystem();
            this.confirmdelete = false;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    async multipleDelete() {
      await this.checkTrashStatus();
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        this.opensdialogmultidelinboxoutbox = true;
      }
      // let datalist = [];
      // this.status_data = "T";
      // if (this.filemultipledownload.length < 1) {
      //   Toast.fire({
      //     icon: "warning",
      //     title: this.$t("myinboxPage.msg.nofile")
      //   });
      // } else {
      //   let api;
      //   let payload;
      //   let filedata = [];
      //   for (let i = 0; i < this.filemultipledownload.length; i++) {
      //     // ยังไม่ได้ดัก folder
      //     if (true) {
      //       filedata.push(this.filemultipledownload[i]["inbox_id"]);
      //     }
      //   }
      //   payload = {
      //     outbox_id: filedata,
      //     account_id: this.dataAccountId
      //   };
      //   api = "/api/delete_outbox";
      //   console.log("Payload =>", JSON.stringify(payload));
      //   console.log(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api);
      //   //API
      //   //เหลือยิงเทสรอผล
      //   this.axios
      //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload)
      //     .then(response => {
      //       console.log("res");
      //       if (response.data.status === "OK") {
      //         Toast.fire({
      //           icon: "success",
      //           title: "OK"
      //         });
      //         this.clearmultipledownload();
      //         this.statusmutipledownload = false
      //         this.getSourceSystem();
      //       } else {
      //         Toast.fire({
      //           icon: "error",
      //           title: response.data.errorMessage
      //         });
      //       }
      //     });
      // }
    },
    filteredItems(search) {
      this.search = search;
    },
    checkSort() {
      // this.loadfolder();
      this.outboxdata = this.buff;
      this.clickSort = false;
      this.multiSort = false;
      this.checkpagination = false;
      // this.multiSortdate=false
      // this.multiSortsize=false
    },
    // sortFile(parameter) {
    //   console.log("parameter----------+++", parameter);
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.outboxdata = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].data_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }
    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.filename.localeCompare(b.filename);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.filename.localeCompare(b.filename);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.filename.localeCompare(a.filename);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.filename.localeCompare(a.filename);
    //       });
    //     }
    //   } else if (this.sortby === "outboxdate") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.cre_dtm.localeCompare(b.cre_dtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.cre_dtm.localeCompare(b.cre_dtm);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.cre_dtm.localeCompare(a.cre_dtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.cre_dtm.localeCompare(a.cre_dtm);
    //       });
    //     }
    //   } else if (this.sortby === "docdate") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.document_date.localeCompare(b.document_date);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.document_date.localeCompare(b.document_date);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.document_date.localeCompare(a.document_date);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.document_date.localeCompare(a.document_date);
    //       });
    //     }
    //   } else if (this.sortby === "docno") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.document_no.localeCompare(b.document_no);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.document_no.localeCompare(b.document_no);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.document_no.localeCompare(a.document_no);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.document_no.localeCompare(a.document_no);
    //       });
    //     }
    //   } else if (this.sortby === "doctype") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.document_type.localeCompare(b.document_type);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.document_type.localeCompare(b.document_type);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.document_type.localeCompare(a.document_type);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.document_type.localeCompare(a.document_type);
    //       });
    //     }
    //   } else if (this.sortby === "receivername") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.receiver_name.localeCompare(b.receiver_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.receiver_name.localeCompare(b.receiver_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.receiver_name.localeCompare(a.receiver_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.receiver_name.localeCompare(a.receiver_name);
    //       });
    //     }
    //   } else if (this.sortby === "sendername") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.sender_name.localeCompare(b.sender_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.sender_name.localeCompare(b.sender_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.sender_name.localeCompare(a.sender_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.sender_name.localeCompare(a.sender_name);
    //       });
    //     }
    //   } else if (this.sortby === "status") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.status.localeCompare(b.status);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.status.localeCompare(b.status);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.status.localeCompare(a.status);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.status.localeCompare(a.status);
    //       });
    //     }
    //   }

    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.outboxdata.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.outboxdata;
    // },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    // ฟังก์ชันสำหรับเอาเอกสารที่ trash = DELETE ออกจากการทำรายการ จากการกด select all
    checkTrashStatus() {
      let checktrashstatus = [];
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        if (
          this.filemultipledownload[i].status !== "pending" &&
          this.filemultipledownload[i].trash !== "DELETE" &&
          this.filemultipledownload[i].data_type !== null
        ) {
          checktrashstatus.push(this.filemultipledownload[i]);
        } else {
          continue;
        }
      }
      this.filemultipledownload = checktrashstatus;
    },
    async multipledownload() {
      await this.checkTrashStatus();
      this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      this.count_loadingfile = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        // console.log("file==========",this.filemultipledownload);
        // this.$store.dispatch("show_loading", true);
        this.snackbars = [];
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          filedata.push(this.filemultipledownload[i]["inbox_id"]);
        }
        console.log(filedata);

        let payload = {
          // list_file: filedata,
          // account_id: this.dataAccountId
          account_id: this.dataAccountId,
          data_type: "outbox",
          inbox_id: filedata,
        };
        // this.snackbardowload = true;
        this.btsdownload = true;
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();
        let config = {
          headers: { Authorization: auth.code },
          // onDownloadProgress: progressEvent => {
          //         let progresspercent = Math.round(
          //           (progressEvent.loaded * 100) / progressEvent.total
          //         );
          //         // console.log(progresspercent);
          //         if (progresspercent === 95) {
          //           this.percentCompleted = 95;
          //         } else {
          //           this.percentCompleted = progresspercent;
          //         }
          //         this.percent = this.percentCompleted;
          //       },
          //       withCredentials: false,
          //       responseType: "arraybuffer",
        };
        await this.axios
          // .post(
          //   process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
          //     "/api/multiple_downloads_file",
          //   payload,config
          // )
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
              "/api/prepare_zip_file_inbox",
            payload,
            config
          )
          .then((response) => {
            // this.statusmutipledownload = false;
            console.log(response);
            this.statusmutipledownload = false;
            console.log(response);
            if (response.status == 200) {
              console.log("response OK", response);
              this.fn_loop_multidownloading(
                response.data.all_file,
                response.data.zip_name,
                response.data.url
              );
              this.count_allfile = response.data.total;
            } else {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            // if (response.statusText === "OK") {
            //   if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
            //     console.log("response < 1000", response);
            //     this.opensnackbarmultidownload = false;
            //     let res_error = gbfdecodearraybuffer.decodearraybuffer(
            //       response.data
            //     );
            //     console.log("responseerror", res_error);
            //     Toast.fire({
            //       icon: "error",
            //       title: res_error.errorMessage,
            //     });
            //   } else {

            //     this.percentCompleted = 100;
            //     this.btsdownload = false;
            //     let headers = response.headers;
            //     let blob = new Blob([response.data],{type:headers['content-type']});
            //     let link = document.createElement('a');
            //     link.href = window.URL.createObjectURL(blob);
            //     link.download = this.OutboxFile + " " + this.datetime;
            //     link.click();
            //     // const blob = new Blob([response.data]);
            //     // const content = response.headers["content-type"];
            //     // saveAs(blob, this.OutboxFile + " " + this.datetime + ".zip");
            //       setTimeout(() => {
            //         this.opensnackbarmultidownload = false
            //         }, 2500);
            //   }
            // }else{
            //   this.opensnackbarmultidownload = false
            // }

            this.clearmultipledownload();
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            // this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
      }
    },

    clickpagination() {
      if(this.check_import_search === true){
      this.checkpagination = true
      this.import_file_for_search()
      }else{
      this.checkpagination = true
      this.searchdocument()
      }
      // this.checkpagination = true
      // console.log("Pagination working ")
      // if (this.checkpagination === true) {
      //   this.checksortorderby();
      // } else {
      //   this.searchdocument();
      //   // this.getSourceSystem();
      // }
    },
    // fn_calculate_size(_sizefilebyte) {
    //   console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
    //    return parseInt((_sizefilebyte / 1073741824).toFixed(2))
    // },
    downloadfile(currentfile) {
      console.log(currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size)
      this.percentCompleted = 0;
      this.percent = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          icon: "warning",
          title: "Coming Soon !!",
        });
        //   let url =
        //     process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //     "/api/v2/download_folder?account_id=" +
        //     this.dataAccountId +
        //     "&folder_id=" +
        //     currentfile.file_id;
        //   this.snackbardowloadonefile = true;
        // this.axios
        //       .get(url,{
        //         onDownloadProgress: (progressEvent) => {
        //           this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //           console.log(this.percentCompleted);
        //           this.namedowload = currentfile.file_name;
        //           if(this.percentCompleted = 95){
        //             this.percentCompleted = 95;
        //           }

        //         },
        //         withCredentials: false,
        //         responseType: "arraybuffer"
        //       })
        //       .then(response => {
        //         if(response.statusText === "OK"){
        //           this.percentCompleted = 100;
        //         }
        //         // console.log("fff",response);
        //         // this.namedowload = currentfile.file_name;
        //         const blob = new Blob([response.data]);
        //         const content = response.headers['content-type'];
        //         saveAs(blob,currentfile.file_name+ ".zip")

        //       })
        //       .catch(error => {
        //         Toast.fire({
        //           icon: "error",
        //           title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
        //         });
        //         console.log(error);
        //       });
      } else {
        this.btsdownload = true;
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file_inbox?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // this.snackbardowloadonefile = true;
        // if(checkfilesize < 1.00){
        this.opensnackbarmultidownload = true;
        this.namedowload = currentfile.filename;
        this.axios
          .get(url, {
            onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // console.log(progresspercent);
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then(async (response) => {
            if (response.status == 200) {
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                (response.headers["content-type"]) ===
                  "application/json"
              ) {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                console.log("responseerror", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                let filename = currentfile.filename;
                if (this.feature_renamedocument) {
                  filename = await this.checkRenameDocument(currentfile);
                }

                this.percentCompleted = 100;
                this.btsdownload = false;

                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, currentfile.filename);

                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 2500);
              }
            } else {
              this.opensnackbarmultidownload = false;
            }
            // console.log("fff",response);
            // this.namedowload = currentfile.file_name;
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้",
            });
            console.log(error);
          });
        // }else{
        //   window.location.assign(url)
        //   this.btsdownload = false;
        //   this.opensnackbarmultidownload = false;
        // }
      }
    },
    async checkRenameDocument(file) {
      let auth = await gbfGenerate.generateToken();
      let filename = file.filename;
      await this.axios
        .get(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_rename_document",   
          {
            headers: { Authorization: auth.code },
            params: { 
              file_id: file.file_id,
              account_id: this.dataAccountId 
            }
          }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            filename = res.data.filename;
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("myoutboxPage.msg.norenamedocument"),
          });
        });
      return filename;
    },
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       icon: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.dataUsername +
    //       "&file_id=" +
    //       currentfile.file_id;
    //     window.location.assign(url);
    //   }
    // },
    // outboxApproved() {
    //   this.openoutboxapproved = true;
    //   this.fakeOutboxApproveData()
    // },
    previewfile(file) {
      // console.log(file);
      this.currentfile = file;
      this.openpreviewfile = true;
    },
    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
    },
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      // this.todateupload = format(new Date(), "yyyy-MM-dd");
      this.todateupload = this.formatDatetodoc2(this.fromdateupload)
    },
    changeFromdatedocument() {
      var datenow = format(new Date(), "yyyy-MM-dd");
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      if(this.fromdatedocument > datenow ){
        this.todatedocument = this.formatDatetodoc2(this.fromdatedocument)
      }else{
        this.todatedocument = this.formatDatetodoc2(this.fromdatedocument)
      }
      // this.todatedocument = format(new Date(), "yyyy-MM-dd");
      // this.todatedocument = this.formatDatetodoc2(this.fromdatedocument)
    },
    getSourceSystem() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.$router.push("/myoutbox-system");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.getDataOutbox(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.getDataExtensionFile(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.getDataDocType(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.getDataOutbox(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.getDataOutbox(this.$route.query.biz, "2");
          }
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.getDataOutbox(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
    async getDataOutbox(_accountid, _type) {
      this.check_import_search = false;
      if (this.statusde == "Active") {
        this.sortStatus = "active";
      }
      this.loaddataprogress = true;
      this.totalfile = 0;
      this.checkdataoutbox = [];
      this.outboxdata = [];
      let offset = this.checkpagination === true ? this.size * (this.page - 1) : 0 
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername === null ? "" : this.sendername ,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid ,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        customer_box: this.oneboxtype.length === 1 ? this.oneboxtype : "",
        status_onechat: this.statusonechat,
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          // console.log("response",res);
          // this.outboxdata = [];
          if (res.data.status === "OK") {
            console.log("response1111", res);

            for (let i = 0; i < res.data.result.data.length; i++) {
              // let file_outbox = {};
              // // file_outbox["cre_dtm"] = this.formatdatetime(res.data.result.data[i].cre_dtm);
              // file_outbox["cre_dtm"] = res.data.result.data[i].cre_dtm;
              // file_outbox["data_type"] = res.data.result.data[i].data_type;
              // // file_outbox["document_date"] = this.setformatdate(res.data.result.data[i].document_date);
              // file_outbox["document_date"] = res.data.result.data[i].document_date;
              // file_outbox["document_no"] = res.data.result.data[i].document_no;
              // file_outbox["document_type"] = res.data.result.data[i].document_type;
              // file_outbox["expired_inbox"] = res.data.result.data[i].expired_inbox;
              // file_outbox["expired_inbox_left"] = res.data.result.data[i].expired_inbox_left;
              // file_outbox["file_id"] = res.data.result.data[i].file_id;
              // file_outbox["filename"] = res.data.result.data[i].filename;
              // file_outbox["inbox_filename"] = res.data.result.data[i].inbox_filename;
              // file_outbox["inbox_id"] = res.data.result.data[i].inbox_id;
              // file_outbox["receiver_name"] = res.data.result.data[i].receiver_name;
              // file_outbox["relation_id"] = res.data.result.data[i].relation_id;
              // file_outbox["relation_name"] = res.data.result.data[i].relation_name;
              // file_outbox["relation_status"] = res.data.result.data[i].relation_status;
              // file_outbox["remark"] = res.data.result.data[i].remark;
              // file_outbox["sender_name"] = res.data.result.data[i].sender_name;
              // file_outbox["status"] = res.data.result.data[i].status;
              // file_outbox["status_onechat"] = this.fn_check_statusonechat(res.data.result.data[i].status_onechat);
              // // file_outbox["status_onechat"] = res.data.result.data[i].status_onechat;
              // file_outbox["tax_refund"] = res.data.result.data[i].tax_refund;
              // file_outbox["trash"] = res.data.result.data[i].trash;

              // this.package = res.data.package;
              //   // if(res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2 ){
              //     this.checkdataoutbox.push(res.data.result.data[i])
              //   // }
              this.checkdataoutbox.push(res.data.result.data[i]);
              // this.checkdataoutbox.push(file_outbox);
              console.log(this.checkdataoutbox);
            }

            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              // console.log("this.checkdataoutbox",this.checkdataoutbox);
              this.outboxdata = this.checkdataoutbox;

              // this.outboxdata = res.data.result["data"];
              console.log("outbox----", this.outboxdata);
              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.outboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    fn_check_statusonechat(parameter) {
      let status_onechat_;
      if (parameter === "" || parameter === "0") {
        status_onechat_ = "ไม่ส่ง";
      } else if (parameter === "1") {
        status_onechat_ = "รอส่ง";
      } else if (parameter === "2") {
        status_onechat_ = "ส่งแล้ว";
      } else if (parameter === "3") {
        status_onechat_ = "อ่านแล้ว";
      } else {
        status_onechat_ = "ไม่ส่ง";
      }
      return status_onechat_;
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    seticon_new(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatDatefromdoc(datefrom,docto) {
      console.log("datefrom1",datefrom);
      console.log("dateto2",docto);
      if((!datefrom || datefrom === undefined)&& docto){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdatedocument = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdatedocument",this.fromdatedocument);
          const [year, month, day] = this.fromdatedocument.split("-");
          return `${year}${month}${day}`;
        }
      }else if(datefrom && docto){
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }else if((!datefrom || datefrom === undefined ) && (!docto || docto === undefined)){
        return ""
      }else if(datefrom && (!docto || docto === undefined)){
        console.log('2');
          console.log("datefrom-", datefrom);
          var dateNow = new Date();
          var date = new Date(datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }
      }
    },
    // formatDatetodoc2(datefrom) {
    //   console.log("datefrom777",datefrom);
    //   // console.log("docto",docto);
    //   var dateNow = new Date();
    //   var date = new Date(datefrom);
    //     console.log('1');
    //     if(datefrom){
    //       console.log('2');
    //       console.log("datefrom", datefrom);
    //       date.setDate(date.getDate() + 90);
        
    //       // if(date > dateNow){
    //       if(this.todatedocument === ''){
    //         console.log('2.1');
    //         let todate = new Date(date).toISOString().substr(0, 10);
    //         // this.todatedocument = new Date(date).toISOString().substr(0, 10);
    //         const [year, month, day] = todate.split("-");
    //         return `${year}-${month}-${day}`;

    //       }else {
    //         let todate_check = new Date(this.todatedocument);
    //         todate_check.setDate(todate_check.getDate() - 90);
    //         console.log("todate_check",todate_check);
    //         let todate = new Date(todate_check).toISOString().substr(0, 10);
    //         const [year, month, day] = todate.split("-");
    //         let todate_check2 = `${year}-${month}-${day}`;
    //         console.log("todate_check2",todate_check2);
    //         if(datefrom > todate_check2){
    //           console.log('3');
    //           let todate = new Date(date).toISOString().substr(0, 10);
    //           // this.todatedocument = new Date().toISOString().substr(0, 10);
    //           const [year, month, day] = todate.split("-");
    //           return `${year}-${month}-${day}`;
    //         }else{
    //           console.log('4');
    //           let todate = new Date(this.todatedocument).toISOString().substr(0, 10);
    //           // this.todatedocument = new Date(date).toISOString().substr(0, 10);
    //           const [year, month, day] = todate.split("-");
    //           return `${year}-${month}-${day}`;
    //         }
    //       }
    //     }

    // },
    formatDatetodoc2(datefrom) {
      console.log("datefrom",datefrom);
      // console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            let todate = new Date(date).toISOString().substr(0, 10);
            // this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }else{
            console.log('4');
            let todate = new Date(date).toISOString().substr(0, 10);
            // this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }
        }

    },
    formatDatetodoc(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
    formatDatefromupload(datefrom,docto) {
      console.log("date",datefrom);
      console.log("date",docto);
      if(!datefrom){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdateupload = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdateupload",this.fromdateupload);
          const [year, month, day] = this.fromdateupload.split("-");
          return `${year}${month}${day}`;
        }
      }else{
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }
    },
    formatDatetoupload(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todateupload = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todateupload = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },

    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },

    setformatdate(_date) {
      if (_date === "" || _date === null || _date === undefined) {
        return "-";
      } else {
        let data =
          _date.substring(8, 6) +
          "/" +
          _date.substring(6, 4) +
          "/" +
          _date.substring(0, 4);
        return data || "-";
      }
    },
    getDataFilters() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          // this.$router.push("/myinbox-system");
        } else {
          this.getDataExtensionFile(
            this.dataAccountActive.business_info["business_id"],
            "2"
          );
          this.getDataDocType(
            this.dataAccountActive.business_info["business_id"],
            "2"
          );
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
    Clear_data() {
      (this.ponumberinput = ""),
      (this.filename = ""),
        (this.fileextension = []),
        (this.fromdateupload = ""),
        (this.todateupload = ""),
        (this.receivername = ""),
        (this.statusonechat = []),
        (this.sendername = ""),
        (this.oneboxtype = []),
        (this.documenttype = []),
        (this.documentid = ""),
        (this.documentidto = ""),
        (this.fromdatedocument = ""),
        (this.todatedocument = ""),
        (this.sellertaxid = ""),
        (this.sellerbranchid = ""),
        (this.buyertaxid = ""),
        (this.buyerbranchid = ""),
        (this.outboxdata = []),
        (this.import_file_document_no = null)
    },
    searchdocument() {
      // console.log("this.filename",this.filename);
      // console.log("this.fileextension",this.fileextension);
      // console.log("this.fromdateupload",this.fromdateupload);
      // console.log("this.todateupload",this.todateupload);
      // console.log("this.receivername",this.receivername);
      // console.log("this.statusonechat",this.statusonechat);
      // console.log("this.sendername",this.sendername);
      // console.log("this.oneboxtype",this.oneboxtype);
      // console.log("this.documenttype",this.documenttype);
      // console.log("this.documentid",this.documentid);
      // console.log("this.documentidto",this.documentidto);
      // console.log("this.fromdatedocument",this.fromdatedocument);
      // console.log("this.todatedocument",this.todatedocument);
      // console.log("this.sellertaxid",this.sellertaxid);
      // console.log("this.sellerbranchid",this.sellerbranchid);
      // console.log("this.buyertaxid",this.buyertaxid);
      // console.log("this.buyerbranchid",this.buyerbranchid);
      if (
        (this.ponumberinput === "" || this.ponumberinput === null) &&
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        this.statusonechat.length == 0 &&
        (this.sendername === "" || this.sendername === null) &&
        (this.oneboxtype.length == 0 || this.oneboxtype.length == null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)
      ){
          this.opendialogalersearchdonotfillout = true;
      }else if(
        (this.ponumberinput === "" || this.ponumberinput === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
         (this.documentid === "" || this.documentid === null) 
      ){
        this.opendialogalersearchdonotfillout = true;
      }
      // else if(
      //   (this.fromdatedocument === "" || this.fromdatedocument === null) &&
      //   (this.todatedocument === "" || this.todatedocument === null) &&
      //   (this.fromdateupload === "" || this.fromdateupload === null) &&
      //   (this.todateupload === "" || this.todateupload === null) && (
      //   (this.documentid !== "" || this.documentid !== null) ||
      //   (this.documentidto !== "" || this.documentidto !== null) )
      // ){
      //   this.panel = false;
      //   this.getSourceSystem();
      // }else if(
      //   (this.fromdatedocument !== ""  &&
      //   this.todatedocument !== ""  ) ||
      //   (this.fromdateupload !== ""  &&
      //   this.todateupload !== "" )
      // ){
      //   this.panel = false;
      //   this.getSourceSystem();
      // }
      else{
        this.panel = false;
        this.getSourceSystem();
      }
    },
    delete_filter(){
      if (
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        this.statusonechat.length == 0 &&
        (this.sendername === "" || this.sendername === null) &&
        (this.oneboxtype.length == 0 || this.oneboxtype.length == null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)&&
        (this.ponumberinput === "" || this.ponumberinput === null)
      ){
          this.opendialogalertcheckdeletefilter = true;
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
        (this.ponumberinput === "" || this.ponumberinput === null)&&
         (this.documentid === "" || this.documentid === null) 
      ){
        this.opendialogalertcheckdeletefilter = true;
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) && (
        (this.documentid !== "" || this.documentid !== null) ||
        (this.documentidto !== "" || this.documentidto !== null) ||
        (this.ponumberinput !== "" || this.ponumberinput !== null)
        )
      ){
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckdeletefilter = true;
        this.getfileoutbox(this.dataAccountActive.business_info["business_id"],"2")
      }else if(
        (this.fromdatedocument !== ""  &&
        this.todatedocument !== ""  ) ||
        (this.fromdateupload !== ""  &&
        this.todateupload !== "" )
      ){
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckdeletefilter = true;
        this.getfileoutbox(this.dataAccountActive.business_info["business_id"],"2")
      }else{
        // this.panel = false;
        // this.getSourceSystem();
        this.opendialogcheckdeletefilter = true;
        this.getfileoutbox(this.dataAccountActive.business_info["business_id"],"2")
      }
    },
    async getfileoutbox(_accountid,_type){
      console.log("getfileoutbox");
      this.total_delete_file = 0;
      let payload = {
        limit: "",
        offset: "",
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        customer_box: this.oneboxtype.length === 1 ? this.oneboxtype : "",
        status_onechat: this.statusonechat,
        sort_by: this.sort_by,
        direction: this.direction,
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          // console.log("response",res);
          // this.outboxdata = [];
          if (res.data.status === "OK") {
            console.log("Ok...Outbox", res);
          }
          this.total_delete_file = res.data.result.total_file
          console.log("total_delete_file",this.total_delete_file);
          this.list_documents = res.data.result
          console.log("list_documents",this.list_documents);
          })
    },

    async getDataDocType(_accountid, _type) {
      let payload = {
        sender_id: _accountid,
        sender_type: _type,
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/outbox_get_all_doctype",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.listDoctype = res.data.result;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDataExtensionFile(_accountid, _type) {
      let payload = {
        sender_id: _accountid,
        sender_type: _type,
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/outbox_get_all_fileextension",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.listExtensionFile = res.data.result;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportfile_import() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfile_import(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfile_import(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfile_import(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfile_import(this.dataAccountId, "1");
      }
    },
    async callexportfile_import(_accountid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      const formData = new FormData();
        for(let i = 0; i<this.$route.query.systemid.length; i++){
          formData.append("system", this.$route.query.systemid[i])
        }
        formData.append("sender_id", _accountid);
        formData.append("sender_type", _type);
        formData.append("type_downloads", this.typedownloads == true ? "Y" : "N");
        formData.append("file", this.import_file_document_no);
        formData.append("status", this.sortStatus);
      
      console.log("formData",formData);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      await this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/get_file_outbox/prepare/downloads", formData, {
          headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log("response",response);
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading(
              response.data.all_file,
              response.data.zip_name,
              response.data.url
            );
            this.count_allfile = response.data.total;
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            if (response.data.errorCode === "ER400" && response.data.errorMessage === "File not found") {
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    exportfile() {
      if(this.check_import_search === true){
        this.exportfile_import()
      }else{
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfile(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfile(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfile(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfile(this.dataAccountId, "1");
      }
      }
    },
    async callexportfile(_accountid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername === null ? "" : this.sendername,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid, 
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        account_id: this.dataAccountId,
        type_downloads: this.typedownloads == true ? "Y" : "N",
        type_document: this.typeitem == true ? "Y" : "N",
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        // onDownloadProgress: progressEvent => {
        //       let progresspercent = Math.round(
        //         (progressEvent.loaded * 100) / progressEvent.total
        //       );
        //       // console.log(progresspercent);
        //       if (progresspercent === 95) {
        //         this.percentCompleted = 95;
        //       } else {
        //         this.percentCompleted = progresspercent;
        //       }
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer"
      };
      await this.axios
        // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v2/outbox_downloads_file_byfilter", payload, config)
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/prepare_zip_file_outbox_by_filter",
          payload,
          config
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading(
              response.data.all_file,
              response.data.zip_name,
              response.data.url
            );
            this.count_allfile = response.data.total;
            // if (response.statusText === "OK") {
            //   if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
            //       console.log("response < 1000", response);
            //       this.snackbardowloadonetotal = false;
            //       let res_error = gbfdecodearraybuffer.decodearraybuffer(
            //         response.data
            //       );
            //       console.log("responseerror", res_error);
            //       Toast.fire({
            //         icon: "error",
            //         title: res_error.errorMessage,
            //       });
            //     } else {

            //       this.percentCompleted = 100;
            //       let headers = response.headers;
            //       let blob = new Blob([response.data],{type:headers['content-type']});
            //       let link = document.createElement('a');
            //       link.href = window.URL.createObjectURL(blob);
            //       link.download = this.ExportOutboxFiles;
            //       link.click();
            //       // const blob = new Blob([response.data]);
            //       // const content = response.headers["content-type"];
            //       // saveAs(blob, this.ExportOutboxFiles + ".zip");
            //       setTimeout(() => {
            //         this.snackbardowloadonetotal = false;
            //       }, 2500);
            //     }
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            if (response.data.errorCode === "ER400" && response.data.errorMessage === "File not found") {
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading(all_file, zip_name, url) {
      console.log("all_file", all_file);
      console.log("zip_name", zip_name);
      console.log("url", url);

      let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังดาวน์โหลดไฟล์";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent", this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;

          // }
          if (res.status !== "OK") {
            console.log("res", res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename;
            this.errorMessage = res.errorMessage;
            this.snackbars.push({
              filename: this.filename_error,
              error: this.errorMessage,
            });
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new(file_id, location_file, zip_name, all_file, url) {
      let url_ =
        url +
        "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file +
        "&account_id=" +
        this.dataAccountId;

      console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage: response.data.errorMessage,
              filename: response.data.filename,
            });
          }, 1);
        });
      });
    },
    download_finish(file_id, location_file, zip_name, path, url) {
      let zip = url.split("zip_id=");
      let zipid = zip[1];
      console.log("url", zip);
      let payload = {
        account_id: this.dataAccountId,
        zip_id: zipid,
        name: this.namefile,
        path: path,
        type_document: this.typeitem == true ? "Y" : "N",
      };
      console.log("payload", payload);
      return new Promise((resolve, reject) => {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/download_file_inbox_finish",
            payload,
            {
              withCredentials: false,
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            this.statusloading = "กำลังรวมไฟล์";
            // console.log("testtt", response.data.status);
            if (response.status == 200) {
              console.log("response++++++++++++ OK", response);
              // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                (response.headers["content-type"]) ===
                  "application/json"
              ) {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                this.snackbardowloadonetotal = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                console.log("responseerror", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percent = 100;
                this.percentCompleted = 100;
                this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                this.btsdownload = false;
                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                // link.download = this.OutboxFile + "_" + this.datetime;
                link.download =
                  format(new Date(), "dd_MM_yyyy") + "_OutboxFiles";
                link.click();
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                  this.snackbardowloadonetotal = false;
                }, 2500);
              }
            }
            this.typedownloads = false;
            this.typeitem = false;
          });
      });
    },
    exportfilecsv_import() {
        console.log("exportfilecsv");
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.download_file_csv_import(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.download_file_csv_import(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.download_file_csv_import(this.$route.query.biz, "2");
          }
        }
      } else {
        this.download_file_csv_import(this.dataAccountId, "1");
      }
    },
    async download_file_csv_import(businessid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      this.showtotalfileonly = true;
      this.count_allfile = this.totalfile;
      const formData = new FormData();
        for(let i = 0; i<this.$route.query.systemid.length; i++){
          formData.append("system", this.$route.query.systemid[i])
        }
        formData.append("sender_id", businessid);
        formData.append("sender_type", _type);
        formData.append("file", this.import_file_document_no);
        formData.append("type_downloads", this.typedownloads == true ? "Y" : "N");
        formData.append("type_document", this.typeitem == true ? "Y" : "N");
        formData.append("sort_by", this.sort_by);
        formData.append("direction", this.direction);
        formData.append("data_type", "csv");
        formData.append("status", this.sortStatus);
      
      console.log("formData",formData);
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      };
      await this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/get_file_outbox/export/excel_csv_files", formData,config)
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_CSV";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้",
          });
          console.log(error);
        });
    },

    exportfilecsv() {
      if(this.check_import_search === true){
        this.exportfilecsv_import();
      }else{
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfilecsv(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfilecsv(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfilecsv(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfilecsv(this.dataAccountId, "1");
      }
      }
    },
    // async callexportfilecsv_new(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   console.log("_accountid",_accountid);
    //   console.log("_type",_type);

    //   let payload = {
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     customer_box:this.oneboxtype.length === 1 ? this.oneboxtype : '',
    //     status:this.sortStatus,
    //     status_onechat:this.statusonechat,
    //     download:"csv",
    //     index:this.listheader
    //   };
    //   this.snackbardowloadonetotalcsv = true;
    //   console.log("payloadoutbox",payload);
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //     onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //   }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_excel_file_outbox_byfilter_costom_index",
    //       payload,config
    //     )
    //     .then(response => {
    //             if (response.statusText === "OK") {
    //               if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //                 console.log("response < 1000", response);
    //                 this.snackbardowloadonetotalcsv = false;
    //                 let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                   response.data
    //                 );
    //                 console.log("responseerror", res_error);
    //                 Toast.fire({
    //                   icon: "error",
    //                   title: res_error.errorMessage,
    //                 });
    //               } else {

    //                 this.percentCompleted = 100;
    //                 // const blob = new Blob([response.data]);
    //                 // const content = response.headers["content-type"];
    //                 // saveAs(blob, this.ExportOutboxFilescsv + ".zip");
    //                 let headers = response.headers;
    //                 let blob = new Blob([response.data],{type:headers['content-type']});
    //                 let link = document.createElement('a');
    //                 link.href = window.URL.createObjectURL(blob);
    //                 link.download = this.ExportOutboxFilescsv;
    //                 link.click();

    //                 setTimeout(() => {
    //                   this.snackbardowloadonetotalcsv = false;
    //                 }, 2500);
    //                   }
    //             }else{
    //               this.snackbardowloadonetotalcsv = false;
    //             }
    //           })
    //           .catch(error => {
    //             Toast.fire({
    //               icon: "error",
    //               title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้"
    //             });
    //             console.log(error);
    //           });
    // },
    // async callexportfilecsv(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   console.log("_accountid",_accountid);
    //   console.log("_type",_type);

    //   let payload = {
    //     account_id: this.dataAccountId,
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     customer_box:this.oneboxtype.length === 1 ? this.oneboxtype : '',
    //     status:this.sortStatus,
    //     status_onechat:this.statusonechat
    //   };
    //   this.snackbardowloadonetotalcsv = true;
    //   this.timeout = -1;
    //   console.log("payloadoutbox",payload);
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //     onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //   }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_csv_file_outbox_byfilter",
    //       payload,config
    //     )
    //     .then(response => {
    //             if (response.statusText === "OK") {
    //               if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //                 console.log("response < 1000", response);
    //                 this.snackbardowloadonetotalcsv = false;
    //                 let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                   response.data
    //                 );
    //                 console.log("responseerror", res_error);
    //                 Toast.fire({
    //                   icon: "error",
    //                   title: res_error.errorMessage,
    //                 });
    //               } else {

    //                 this.percentCompleted = 100;
    //                 // const blob = new Blob([response.data]);
    //                 // const content = response.headers["content-type"];
    //                 // saveAs(blob, this.ExportOutboxFilescsv + ".zip");
    //                 let headers = response.headers;
    //                 let blob = new Blob([response.data],{type:headers['content-type']});
    //                 let link = document.createElement('a');
    //                 link.href = window.URL.createObjectURL(blob);
    //                 link.download = this.ExportOutboxFilescsv;
    //                 link.click();

    //                 setTimeout(() => {
    //                   this.snackbardowloadonetotalcsv = false;
    //                 }, 2500);
    //                   }
    //             }else{
    //               this.snackbardowloadonetotalcsv = false;
    //             }
    //           })
    //           .catch(error => {
    //             Toast.fire({
    //               icon: "error",
    //               title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้"
    //             });
    //             console.log(error);
    //           });
    // },

    // อันเก่าแบบ 3 step
    // async callexportfilecsv(businessid, _type) {
    //   this.percentCompleted = 0;
    //   this.percent = 0;
    //   this.count_allfile = 0;
    //   this.count_loadingfile = 0;
    //   this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
    //   let payload = {
    //     po_number: this.ponumberinput === null ? "" : this.ponumberinput,
    //     sender_id: [businessid],
    //     sender_type: _type,
    //     filename: this.filename === null ? "" : this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid === null ? "" : this.documentid,
    //     doc_no_end: this.documentidto === null ? "" : this.documentidto,
    //     doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
    //     doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
    //     cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername === null ? "" : this.sendername,
    //     sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
    //     receiver_name: this.receivername === null ? "" : this.receivername,
    //     receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
    //     status: this.sortStatus,
    //     customer_box: "",
    //     status_onechat: [],
    //     type_downloads: this.typedownloads == true ? "Y" : "N",
    //     type_document: this.typeitem == true ? "Y" : "N",
    //     sort_by: this.sort_by,
    //     direction: this.direction,
    //   };
    //   // this.snackbardowloadonetotal = true;
    //   this.opensnackbarmultidownload = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code },
    //   };
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/prepare/download_csv_file_outbox_byfilter",
    //       payload,
    //       config
    //     )
    //     .then((response) => {
    //       if (response.data.status === "OK" && response.data.total > 0) {
    //         console.log("response", response);
    //         this.fn_loop_multidownloading_csv(
    //           response.data.all_file,
    //           response.data.zip_name,
    //           response.data.url
    //         );
    //         this.count_allfile = response.data.total;
    //       } else {
    //         // this.snackbardowloadonetotal = false;
    //         this.opensnackbarmultidownload = false;
    //         if (response.data.total <= 0) {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
    //           });
    //         } else {
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.opensnackbarmultidownload = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },

    // แบบใหม่ยิง 2 เส้น 1.จำนวนไฟล์ที่ดาวน์โหลด 2.ดาวน์โหลดไฟล์
    async callexportfilecsv(businessid, _type) {
      // this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      // this.count_loadingfile = 0;
      this.showtotalfileonly = true;
      // this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        sender_id: [businessid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername === null ? "" : this.sendername,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        customer_box: "",
        status_onechat: [],
        type_downloads: this.typedownloads == true ? "Y" : "N",
        type_document: this.typeitem == true ? "Y" : "N",
        sort_by: this.sort_by,
        direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
      };
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/V2/count_download_file_outbox_byfilter",
          payload,
          config
        )
        .then((response) => {
          if (response.data.status === "ER") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          } else {
            if (response.data.total <= 0) {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              console.log("response--", response);
              this.count_allfile = response.data.total;
              this.download_file_csv(businessid, _type);
            }
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้",
          });
          console.log(error);
        });
    },
    async download_file_csv(businessid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      // this.count_allfile = 0;
      // this.count_loadingfile = 0;
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        sender_id: [businessid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername === null ? "" : this.sendername,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        customer_box: "",
        status_onechat: [],
        type_downloads: this.typedownloads == true ? "Y" : "N",
        type_document: this.typeitem == true ? "Y" : "N",
        sort_by: this.sort_by,
        direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      };
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/V2/download_csv_file_outbox_byfilter",
          payload,
          config
        )
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_CSV";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_csv(all_file, zip_name, url) {
      console.log("all_file", all_file);
      console.log("zip_name", zip_name);
      console.log("url", url);

      let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ CSV";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_csv(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent", this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;

          // }
          if (res.status !== "OK") {
            console.log("res", res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename;
            this.errorMessage = res.errorMessage;
            this.snackbars.push({
              filename: this.filename_error,
              error: this.errorMessage,
            });
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_csv(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_csv(file_id, location_file, zip_name, all_file, url) {
      let url_ =
        url +
        "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

      console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage: response.data.errorMessage,
              filename: response.data.filename,
            });
          }, 1);
        });
      });
    },
    download_finish_csv(file_id, location_file, zip_name, path, url) {
      let zip = url.split("zip_id=");
      let zipid = zip[1];
      console.log("url", zip);
      let payload = {
        account_id: this.dataAccountId,
        zip_id: zipid,
        name: this.namefile,
        path: path,
        type_document: this.typeitem == true ? "Y" : "N",
      };
      console.log("payload", payload);
      return new Promise((resolve, reject) => {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/download_outbox_csv_finish",
            payload,
            {
              withCredentials: false,
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            this.statusloading = "กำลังรวมไฟล์";
            // console.log("testtt", response.data.status);
            if (response.status == 200) {
              console.log("response++++++++++++ OK", response);
              // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                (response.headers["content-type"]) ===
                  "application/json"
              ) {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                // this.snackbardowloadonetotal = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                console.log("responseerror", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percent = 100;
                this.percentCompleted = 100;
                this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                this.btsdownload = false;
                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                // link.download = this.OutboxFile + "_" + this.datetime;
                link.download =
                  format(new Date(), "dd_MM_yyyy") + "_Outbox_CSV";
                link.click();
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                  // this.snackbardowloadonetotal = false;
                }, 2500);
              }
            }
            this.typedownloads = false;
            this.typeitem = false;
          });
      });
    },
    exportfileexcel_import() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.download_file_excel_import(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.download_file_excel_import(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.download_file_excel_import(this.$route.query.biz, "2");
          }
        }
      } else {
        this.download_file_excel_import(this.dataAccountId, "1");
      }
    },
    async download_file_excel_import(_accountid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      this.showtotalfileonly = true;
      this.count_allfile = this.totalfile;
      const formData = new FormData();
        for(let i = 0; i<this.$route.query.systemid.length; i++){
          formData.append("system", this.$route.query.systemid[i])
        }
        formData.append("sender_id", _accountid);
        formData.append("sender_type", _type);
        formData.append("file", this.import_file_document_no);
        formData.append("type_downloads", this.typedownloads == true ? "Y" : "N");
        formData.append("type_document", this.typeitem == true ? "Y" : "N");
        formData.append("sort_by", this.sort_by);
        formData.append("direction", this.direction);
        formData.append("data_type", "xlsx");
        formData.append("status", this.sortStatus);
      
      console.log("formData",formData);
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      };
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/v1/get_file_outbox/export/excel_csv_files",
          formData,
          config
        )
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_Excel";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },

    exportfileexcel() {
      if(this.check_import_search === true){
        console.log("exportfileexcel_import");
        this.exportfileexcel_import()
      }else{
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfileexcel(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfileexcel(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfileexcel(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfileexcel(this.dataAccountId, "1");
      }
      }
    },
    // async callexportfileexcel_new(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   console.log("_accountid",_accountid);
    //   console.log("_type",_type);

    //   let payload = {
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     customer_box:this.oneboxtype.length === 1 ? this.oneboxtype : '',
    //     status:this.sortStatus,
    //     status_onechat:this.statusonechat,
    //     download:"excel",
    //     index:this.listheader
    //   };
    //   this.snackbardowloadonetotalexcel = true;
    //   this.timeout = -1;
    //   console.log("payloadoutbox",payload);
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //     onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //   }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_excel_file_outbox_byfilter_costom_index",
    //       payload,config
    //     )
    //     .then(response => {
    //             if (response.statusText === "OK") {
    //               if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //                 console.log("response < 1000", response);
    //                 this.snackbardowloadonetotalexcel = false;
    //                 let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                   response.data
    //                 );
    //                 console.log("responseerror", res_error);
    //                 Toast.fire({
    //                   icon: "error",
    //                   title: res_error.errorMessage,
    //                 });
    //               } else {
    //                 this.percentCompleted = 100;
    //                 // const blob = new Blob([response.data]);
    //                 // const content = response.headers["content-type"];
    //                 // saveAs(blob, this.ExportOutboxFilesexcel + ".zip");
    //                 let headers = response.headers;
    //                 let blob = new Blob([response.data],{type:headers['content-type']});
    //                 let link = document.createElement('a');
    //                 link.href = window.URL.createObjectURL(blob);
    //                 link.download = this.ExportOutboxFilesexcel;
    //                 link.click();

    //                 setTimeout(() => {
    //                   this.snackbardowloadonetotalexcel = false;
    //                 }, 2500);
    //               }
    //             }else{
    //                this.snackbardowloadonetotalexcel = false;
    //             }
    //           })
    //           .catch(error => {
    //             Toast.fire({
    //               icon: "error",
    //               title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้"
    //             });
    //             console.log(error);
    //           });
    // },
    // async callexportfileexcel(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   console.log("_accountid",_accountid);
    //   console.log("_type",_type);

    //   let payload = {
    //     account_id: this.dataAccountId,
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     customer_box:this.oneboxtype.length === 1 ? this.oneboxtype : '',
    //     status:this.sortStatus,
    //     status_onechat:this.statusonechat
    //   };
    //   this.snackbardowloadonetotalexcel = true;
    //   this.timeout = -1;
    //   console.log("payloadoutbox",payload);
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //     onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //   }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_excel_file_outbox_byfilter",
    //       payload,config
    //     )
    //     .then(response => {
    //             if (response.statusText === "OK") {
    //               if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //                 console.log("response < 1000", response);
    //                 this.snackbardowloadonetotalexcel = false;
    //                 let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                   response.data
    //                 );
    //                 console.log("responseerror", res_error);
    //                 Toast.fire({
    //                   icon: "error",
    //                   title: res_error.errorMessage,
    //                 });
    //               } else {
    //                 this.percentCompleted = 100;
    //                 // const blob = new Blob([response.data]);
    //                 // const content = response.headers["content-type"];
    //                 // saveAs(blob, this.ExportOutboxFilesexcel + ".zip");
    //                 let headers = response.headers;
    //                 let blob = new Blob([response.data],{type:headers['content-type']});
    //                 let link = document.createElement('a');
    //                 link.href = window.URL.createObjectURL(blob);
    //                 link.download = this.ExportOutboxFilesexcel;
    //                 link.click();

    //                 setTimeout(() => {
    //                   this.snackbardowloadonetotalexcel = false;
    //                 }, 2500);
    //               }
    //             }else{
    //                this.snackbardowloadonetotalexcel = false;
    //             }
    //           })
    //           .catch(error => {
    //             Toast.fire({
    //               icon: "error",
    //               title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้"
    //             });
    //             console.log(error);
    //           });
    // },
    
    // อันเก่าแบบ 3 step
    // async callexportfileexcel(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   this.percent = 0;
    //   this.count_allfile = 0;
    //   this.count_loadingfile = 0;
    //   this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
    //   let payload = {
    //     // account_id: this.dataAccountId,
    //     po_number: this.ponumberinput === null ? "" : this.ponumberinput,
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename === null ? "" : this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid === null ? "" : this.documentid,
    //     doc_no_end: this.documentidto === null ? "" : this.documentidto,
    //     doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
    //     doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
    //     cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername === null ? "" : this.sendername,
    //     sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
    //     receiver_name: this.receivername === null ? "" : this.receivername,
    //     receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
    //     status: this.sortStatus,
    //     customer_box: "",
    //     status_onechat: [],
    //     type_downloads: this.typedownloads == true ? "Y" : "N",
    //     type_document: this.typeitem == true ? "Y" : "N",
    //     sort_by: this.sort_by,
    //     direction: this.direction,
    //   };
    //   // this.snackbardowloadonetotal = true;
    //   this.opensnackbarmultidownload = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code },
    //   };
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/prepare/download_excel_file_outbox_byfilter",
    //       payload,
    //       config
    //     )
    //     .then((response) => {
    //       if (response.data.status === "OK" && response.data.total > 0) {
    //         console.log("response", response);
    //         this.fn_loop_multidownloading_excel(
    //           response.data.all_file,
    //           response.data.zip_name,
    //           response.data.url
    //         );
    //         this.count_allfile = response.data.total;
    //       } else {
    //         // this.snackbardowloadonetotal = false;
    //         this.opensnackbarmultidownload = false;
    //         if (response.data.total <= 0) {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
    //           });
    //         } else {
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.opensnackbarmultidownload = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },

    // แบบใหม่ยิง 2 เส้น 1.จำนวนไฟล์ที่ดาวน์โหลด 2.ดาวน์โหลดไฟล์
    async callexportfileexcel(_accountid, _type) {
      // this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      // this.count_loadingfile = 0;
      this.showtotalfileonly = true;
      // this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let payload = {
        // account_id: this.dataAccountId,
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername === null ? "" : this.sendername,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        customer_box: "",
        status_onechat: [],
        type_downloads: this.typedownloads == true ? "Y" : "N",
        type_document: this.typeitem == true ? "Y" : "N",
        sort_by: this.sort_by,
        direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;   
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },        
      };
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/V2/count_download_file_outbox_byfilter",
          payload,
          config
        )
        .then((response) => {
          if (response.data.status === "ER") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;            
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          } else {
            if (response.data.total <= 0) {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              console.log("response--", response);
              this.count_allfile = response.data.total;
              this.download_file_excel(_accountid, _type);
            }
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },
    async download_file_excel(_accountid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      // this.count_allfile = 0;
      // this.count_loadingfile = 0;
      let payload = {
        // account_id: this.dataAccountId,
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername === null ? "" : this.sendername,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        customer_box: "",
        status_onechat: [],
        type_downloads: this.typedownloads == true ? "Y" : "N",
        type_document: this.typeitem == true ? "Y" : "N",
        sort_by: this.sort_by,
        direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      };
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/V2/download_excel_file_outbox_byfilter",
          payload,
          config
        )
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_Excel";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_excel(all_file, zip_name, url) {
      console.log("all_file", all_file);
      console.log("zip_name", zip_name);
      console.log("url", url);

      let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ Excel";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_excel(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent", this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;

          // }
          if (res.status !== "OK") {
            console.log("res", res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename;
            this.errorMessage = res.errorMessage;
            this.snackbars.push({
              filename: this.filename_error,
              error: this.errorMessage,
            });
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_excel(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_excel(
      file_id,
      location_file,
      zip_name,
      all_file,
      url
    ) {
      let url_ =
        url +
        "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

      console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage: response.data.errorMessage,
              filename: response.data.filename,
            });
          }, 1);
        });
      });
    },
    download_finish_excel(file_id, location_file, zip_name, path, url) {
      let zip = url.split("zip_id=");
      let zipid = zip[1];
      console.log("url", zip);
      let payload = {
        account_id: this.dataAccountId,
        zip_id: zipid,
        name: this.namefile,
        path: path,
        type_document: this.typeitem == true ? "Y" : "N",
      };
      console.log("payload", payload);
      return new Promise((resolve, reject) => {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/download_file_excel_finish_outbox",
            payload,
            {
              withCredentials: false,
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            this.statusloading = "กำลังรวมไฟล์";
            // console.log("testtt", response.data.status);
            if (response.status == 200) {
              console.log("response++++++++++++ OK", response);
              // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                (response.headers["content-type"]) ===
                  "application/json"
              ) {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                // this.snackbardowloadonetotal = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                console.log("responseerror", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percent = 100;
                this.percentCompleted = 100;
                this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                this.btsdownload = false;
                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                // link.download = this.OutboxFile + "_" + this.datetime;
                link.download =
                  format(new Date(), "dd_MM_yyyy") + "_Outbox_Excel";
                link.click();
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                  // this.snackbardowloadonetotal = false;
                }, 2500);
              }
            }
            this.typedownloads = false;
            this.typeitem = false;
          });
      });
    },

    fn_setheader(type) {
      console.log(type);
      this.typedownload = type;
      this.opendialogselectheader = true;
    },
    checkdownloadcsvandexcel(item) {
      this.listheader = [];
      console.log("item", item);
      for (let i = 0; i < item.length; i++) {
        if (item[i].select !== null) {
          this.listheader.push(item[i].header_name);
        }
      }
      console.log("listheader", this.listheader);
      if (this.typedownload === "csv") {
        this.exportfilecsv();
      } else {
        this.exportfileexcel();
      }
    },
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1024
            : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1048576
            : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1073741824
            : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1099511627776
            : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0]
            : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },
    async getfeature() {
      var payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/get_default_inbox",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.feature = response.data.default_outbox;
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    callDateNow() {
      let dateNow = new Date();
      return new Date(dateNow).toISOString().substr(0, 10);
    },
    selectItemTable(item) {
      return this.selectColor(item, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    selectColor(e, type) {
      if (type === "table") {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      } else {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      }
    },
    setSelectitemtable(file) {
      console.log('file',file)
      this.arrayfileidselect = [];
      if (file.file_id) {
        this.arrayfileidselect = [];
        if (this.statusmutipledownload === false) {
          this.arrayfileidselect = [file.file_id]
        }
      }
    },
  },
  created() {
    this.ponumberinput = this.$route.query.po_number || "",
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.filename = this.$route.query.filename || "";
    this.fileextension = this.$route.query.fileextension || "";
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.fromdatedocument = this.$route.query.fromdatedoc || "";
    this.todatedocument = this.$route.query.todatedoc || "";
    this.documentid = this.$route.query.doc_no_start || "";
    this.documentidto = this.$route.query.doc_no_end || "";
    this.sendername = this.$route.query.sender_name || "";
    this.sellertaxid = this.$route.query.sellerTaxid || "";
    this.sellerbranchid = this.$route.query.sellerBranchid || "";
    this.receivername = this.$route.query.receiver_name || "";
    this.buyertaxid = this.$route.query.receiverTaxid || "";
    this.buyerbranchid = this.$route.query.receiverBranchid || "";
    this.checkstatusalloutbox = this.$route.query.checkstatusalloutbox || "";
  },
  mounted() {
    let fromAllbizoutbox = VueCookies.get("fromAllbizoutbox");
    if(fromAllbizoutbox === 'true'){
      this.getSourceSystem();
      VueCookies.set("fromAllbizoutbox", false);
    }
    this.alertcondition();
    this.getDataFilters();
    this.getfeature();
    //   console.log(this.$router.app['name'])

    this.fromdatedocument = this.setdate_(format(new Date(), "yyyy-MM-dd"));
    this.todatedocument = format(new Date(), "yyyy-MM-dd");
  },
};
</script>

<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainer_outboxsourcesystem {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}
</style>
